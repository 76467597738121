<template>
  <v-container
    class="py-0"
    id="dashboard"
    fluid
    tag="section"
  >
    <base-material-card
      color="success"
      inline
      class="px-5 py-3"
      filter
    >
      <template #heading>
        <h1 class="text-h3 font-weight-light">
          Конструктор шаблонов
        </h1>
      </template>
      <h2 class="title-step">{{ nameStep }}</h2>
      <template #after-heading>
        <v-btn
          v-if="curStep!=='table'"
          color="success"
          @click="addElem(curStep)"
        >
          Добавить {{curStep ? buttonAddName : 'категорию'}}
        </v-btn>
      </template>
      <v-row>
        <v-col cols="3">
          <template>
            <v-list>
              <v-list-group
                v-for="category in planningListCategories"
                :key="'category-' + category.id"
                :prepend-icon="'mdi-card-bulleted-settings-outline'"
                @click="getTemplate(category)"
              >
                <template #activator>
                  <v-list-item-title>
                    {{ category.title }} ({{ category.id }})
                  </v-list-item-title>
                </template>
                <v-list-group
                  v-for="template in planningListTemplates"
                  v-model="template.selected"
                  :key="'template-' + template.id"
                  sub-group
                  no-action
                  @click="getTabs(template)"
                >
                  <template #activator>
                    <v-list-item-icon>
                      <v-icon>mdi-mirror-rectangle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
<!--                      @click="showPageMeta(page)"-->
                      <v-list-item-title>{{ template.title }} ({{ template.id }})</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-group
                    v-for="tab in planningListTabs"
                    v-model="tab.selected"
                    :key="'tab-' + tab.title"
                    :group="'section-' + tab.title"
                    sub-group
                    no-action
                    color="primary"
                    @click="getTable(tab)"
                  >
<!--                    @click="hideContent()"-->
                    <template #activator>
                      <v-list-item-icon>
                        <v-icon>mdi-format-list-group</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ tab.title }} ({{ tab.id }})
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="tables in planningListTables"
                      :key="'tables-' + tables.title"
                      sub-group
                      @click="selectTable(tables)"
                      :class="tables === curPosition.chosenTable ? 'primary--text' : ''"
                    >
                      <v-list-item-icon>
                        <v-icon :class="tables === curPosition.chosenTable ? 'primary--text' : ''">mdi-table-large</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ tables.title }} ({{ tables.id }})
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                </v-list-group>
              </v-list-group>
            </v-list>
          </template>
        </v-col>
        <v-divider vertical />
        <v-col
          sm="9"
          class="pl-6"
        >
          <v-list mode="out-in">
            <builder-template-list
              v-if="!curStep"
              :name="'Категории'"
              :selected-fields-on-list="categoryItemsList"
              @editElement="editCategory"
            />
            <builder-template-list
              v-if="curStep === 'category'"
              :list="planningListTemplates"
              :name="'Шаблоны'"
              :selected-fields-on-list="templateItemsList"
              @editElement="editReports"
            />
            <builder-template-list
              v-if="curStep === 'template'"
              :list="planningListTabs"
              :can-drag="true"
              :name="'Вкладки'"
              :selected-fields-on-list="tabsItemsList"
              @editElement="editTabs"
            />
            <builder-template-list
              v-if="curStep === 'tab'"
              :list="planningListTables"
              :can-drag="true"
              :side-panel="false"
              :name="'Таблицы'"
              @editElement="editTabs"
            />
            <builder-template-table
              v-if="curStep === 'table' && Object.keys(curTableInfoCopy).length !== 0"
              :table-info="curTableInfoCopy"
              :planning-list-tables="curPosition.chosenTable"
              class="main-area text-h6 text--lighten-1 font-weight-light"
              style="align-self: center; display: flex; gap: 20px"
            />
          </v-list>
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog
      v-model="showAddCategory"
      class="dialog-modal-add"
      overlay-opacity="0.96"
      max-width="750"
    >
      <v-card>
        <v-card-text>
          <v-form>
            <v-text-field
              label="Название категории"
              v-model="newCategory.title"
              clearable
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center align-center">
          <v-btn
            color="primary"
            @click="addCategory"
          >
            Создать
          </v-btn>
          <v-btn
            color="red"
            @click="closeAddDialogs"
          >
            Назад
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddTemplate"
      class="dialog-modal-add"
      overlay-opacity="0.96"
      max-width="750"
    >
      <v-card>
        <v-card-text>
          <v-form>
            <v-text-field
              label="id категории"
              v-model="newTemplate.plReportCategoryId"
              clearable
              disabled
            />
            <v-text-field
              label="Название шаблона"
              v-model="newTemplate.title"
              clearable
            />
            <v-text-field
              label="SYSNAME"
              v-model="newTemplate.sysName"
              clearable
              @input="(event) => validationSysName(event, newTemplate)"
            />
            <v-text-field
              label="DESCRIPTION"
              v-model="newTemplate.description"
              clearable
            />
            <v-checkbox
              v-model="newTemplate.flgActive"
              label="FLGACTIVE"
            />
            <v-select
              v-model="newTemplate.forPeriod"
              :items="forPeriod"
              item-value="value"
              item-text="label"
              label="FORPERIOD"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center align-center">
          <v-btn
            color="primary"
            @click="addTemplate"
          >
            Создать
          </v-btn>
          <v-btn
            color="red"
            @click="closeAddDialogs"
          >
            Назад
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddTab"
      class="dialog-modal-add"
      overlay-opacity="0.96"
      max-width="750"
    >
      <v-card>
        <v-card-text>
          <v-form>
            <v-text-field
              label="PLREPORTID"
              v-model="newTab.plReportId"
              clearable
              disabled
            />
            <v-text-field
              label="Название вкладки"
              v-model="newTab.title"
              clearable
            />
            <v-text-field
              label="SORTINDEX"
              v-model="newTab.sortIndex"
              clearable
              type="number"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center align-center">
          <v-btn
            color="primary"
            @click="addTab"
          >
            Создать
          </v-btn>
          <v-btn
            color="red"
            @click="closeAddDialogs"
          >
            Назад
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddTable"
      class="dialog-modal-add"
      overlay-opacity="0.96"
      max-width="850"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="newTable.title"
                label="1.Название таблицы"
                required
                clearable
              />
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="newTable.sysName"
                label="2.SYSNAME"
                required
                clearable
                @input="(event) => validationSysName(event, newTable)"
              />
            </v-col>
            <v-col sm="4">
              <v-checkbox
                v-model="newTable.flgActive"
                label="3.FLGACTIVE (1 по умолчанию)"
              />
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="newTable.plReportId"
                label="4.PLREPORTID (К какому отчету она пренадлежит)"
                required
                clearable
                disabled
                type="number"
              />
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="newTable.plReportTabId"
                label="5.PLREPORTTABID (На какой вкладке находится)"
                required
                clearable
                disabled
                type="number"
              />
            </v-col>
            <v-col sm="4">
              <v-checkbox
                v-model="newTable.flgDynamicRows"
                label="6.FLGDYNAMICROWS (Управление динамич строками по умол. 0, появляется +)"
              />
            </v-col>
            <v-col sm="4">
              <v-checkbox
                v-model="newTable.flgPeriods"
                label="7.FLGPERIODS (Переодическая ли таблица по умолч. 1 (МБ не используется, и будет удалена))"
              />
            </v-col>
            <v-col sm="4">
              <v-checkbox
                v-model="newTable.flgRightSadvanced"
                label="8.FLGRIGHTSADVANCED (Нужны дополнительные права на отображение таблицы, по умолч. 0 (не возвращается на фронт чисто в базу))"
              />
            </v-col>
            <v-col
              class="edit-wrapper"
              sm="4"
            >
              <v-text-field
                v-model="newTable.sortIndex"
                label="9.SORTINDEX (Порядок следования на вкладке)"
                required
                clearable
                type="number"
              />
            </v-col>
            <v-col sm="4">
              <v-checkbox
                v-model="newTable.flgVisible"
                label="10.FLGVISIBLE (Видимая ли она в презентации)"
              />
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="newTable.parentTableId"
                label="11.PARENTTABLEID (Ссылка на родительскую таблицу. Используется в динамических таблицах (flgdynamicrows=1))"
                clearable
                type="number"
              />
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="newTable.plListId"
                label="12.PLLISTID (Комбобоксы уточнить)"
                clearable
                type="number"
              />
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="newTable.headerHeight"
                label="13.HEADERHEIGHT Высота шапки по умол. (40px)"
                clearable
              />
            </v-col>
            <v-col sm="4">
              <v-checkbox
                v-model="newTable.flgFilterData"
                label="14.FLGFITLERDATA (По умол 0, уточнить)"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center align-center">
          <v-btn
            color="primary"
            @click="addTable"
          >
            Создать
          </v-btn>
          <v-btn
            color="red"
            @click="closeAddDialogs"
          >
            Назад
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <mdialog ref="dlgDel" />
    <dataLoading />
  </v-container>
</template>

<script>
  import dataLoading from '../../components/dataLoading.vue'
  // import tableSettings from './builderTemplateSettingsTableInTable.vue'
  import builderTemplateList from './builderTemplateList.vue'
  import builderTemplateTable from './builderTemplateTable.vue'
  import mdialog from '../../../../components/mdialog.vue'
  import { mapGetters } from 'vuex'
  export default {
    name: 'builderTemplateIndex',
    components: {
      dataLoading,
      // tableSettings,
      builderTemplateList,
      builderTemplateTable,
      mdialog,
    },
    data () {
      return {
        categoryItemsList: [
          {
            field: 'id',
            label: '1.id',
            type: 'number',
            disabled: true,
          },
          {
            field: 'title',
            label: '2.Название категории',
            type: 'number',
          },
        ],
        templateItemsList: [
          {
            field: 'id',
            label: 'id',
            disabled: true,
          },
          {
            field: 'title',
            label: 'Название шаблона',
          },
          {
            field: 'sysname',
            label: 'SYSNAME',
          },
          {
            field: 'description',
            label: 'DESCRIPTION',
          },
          {
            field: 'flgactive',
            label: 'FLGACTIVE',
            checkbox: true,
          },
          {
            field: 'forperiod',
            label: 'FORPERIOD',
            select: true,
            selectType: 'forPeriod',
          },
        ],
        tabsItemsList: [
          {
            field: 'id',
            label: 'id',
            type: 'number',
            disabled: true,
          },
          {
            field: 'title',
            label: 'Название вкладки',
            type: 'number',
          },
          {
            field: 'sortindex',
            label: 'SORTINDEX',
          },
        ],
        planningListCategories: [],
        chosenCategory: null,
        chosenCategoryInlist: null,

        planningListTemplates: [],
        chosenTemplate: null,
        chosenTemplateInlist: null,

        planningListTabs: [],
        chosenTab: null,
        chosenTabInlist: null,
        chosenTable: null,
        planningListTables: [],

        curPosition: {
          chosenCategory: null,
          chosenTemplate: null,
          chosenTab: null,
          chosenTable: null,
        },
        curStep: null,
        planningTables: {
          id: null,
          title: null,
          sysName: null,
          flgActive: true,
          plReportId: null,
          plReportTabId: null,
          flgDynamicRows: false,
          flgPeriods: true,
          flgRightSadvanced: false,
          sortIndex: null,
          flgVisible: true,
          parentTableId: null,
          plListId: null,
          headerHeight: '40px',
          flgFilterData: null,
        },
        showAddCategory: false,
        showAddTemplate: false,
        showAddTab: false,
        showAddTable: false,
        newCategory: {
          title: null,
        },
        newTemplate: {
          plReportCategoryId: null,
          title: null,
          sysName: null,
          description: null,
          flgActive: 1,
          forPeriod: null,
        },
        newTab: {
          id: '',
          plReportId: null,
          title: null,
          sortIndex: null,
          reportTitle: '',
        },
        newTable: {
          title: null,
          sysName: null,
          flgActive: true,
          plReportId: null,
          plReportTabId: null,
          flgDynamicRows: false,
          flgPeriods: true,
          flgRightSadvanced: false,
          sortIndex: null,
          flgVisible: true,
          parentTableId: null,
          plListId: null,
          headerHeight: '40px',
          flgFilterData: null,
        },
        changedCol: {
          id: null,
          title: null,
          sysName: null,
          flgActive: true,
          plTableId: null,
          plListId: null,
          description: null,
          dataMask: null,
          dataType: null,
          dataPeriodShift: null,
          sortIndex: null,
          flgVisible: true,
          colType: 0,
          periodIcColsCount: null,
          plGroupId: null,
          width: null,
          plCalcGroupId: null,
          flgPresentVisible: true,
        },
        changedRow: {
          id: null,
          title: null,
          sysName: null,
          flgActive: true,
          description: null,
          plTableId: null,
          plGroupId: null,
          dataMask: null,
          dataType: null,
          dataPeriodShift: null,
          sortIndex: null,
          flgVisible: true,
          flgNumerated: false,
          flgDynamic: false,
          height: '40px',
          plCalcGroupId: null,
          flgPresentVisible: true,
          plGroupParentRowId: null,
          flgColLapsed: null,
        },
        changedRowDefault: {
          id: null,
          title: null,
          sysName: null,
          flgActive: true,
          description: null,
          plTableId: null,
          plGroupId: null,
          dataMask: null,
          dataType: null,
          dataPeriodShift: null,
          sortIndex: null,
          flgVisible: true,
          flgNumerated: false,
          flgDynamic: false,
          height: '40px',
          plCalcGroupId: null,
          flgPresentVisible: true,
          plGroupParentRowId: null,
          flgColLapsed: null,
        },
        curTableInfo: {},
        curTableInfoCopy: {},
        hoverCol: null,
        choiceColActive: null,
        choiceColActiveFromSettings: null,
        hoverRow: null,
        choiceRowActive: null,
        choiceRowActiveFromSettings: null,
        forPeriod: [
          {
            value: 1,
            label: '1 - год',
          },
          {
            value: 2,
            label: '2 - месяц+год',
          },
          {
            value: 3,
            label: '3 - неделя+год',
          },
          {
            value: 4,
            label: '4 - день',
          },
        ],
        colType: [
          {
            value: -2,
            label: '-2 - основной столбец',
          },
          {
            value: -1,
            label: '-1 - столбец "№ п/п"',
          },
          {
            value: 0,
            label: '0 - обычный столбец (статический',
          },
          {
            value: 1,
            label: '1 - периодический столбец: годы',
          },
          {
            value: 2,
            label: '2 - периодический столбец: месяцы',
          },
          {
            value: 3,
            label: '3 - периодический столбец: недели',
          },
          {
            value: 4,
            label: '4 - периодический столбец: дни',
          },
        ],
        dataType: [
          {
            value: null,
            label: '0 - Не выбрано',
          },
          {
            value: 1,
            label: '1 - value_int',
          },
          {
            value: 2,
            label: '2 - value_dbl',
          },
          {
            value: 3,
            label: '3 - value_str',
          },
        ],
        listTabsTables: [
          {
            id: 1,
            name: 'Основные',
            baseName: 'basic',
            from: 'table',
            selected: true,
          },
          {
            id: 2,
            name: 'Стили',
            baseName: 'style',
            from: 'table',
            selected: false,
          },
          {
            id: 3,
            name: 'Метки',
            baseName: 'mark',
            from: 'table',
            selected: false,
          },
        ],
        listTabsCol: [
          {
            id: 1,
            name: 'Основные',
            baseName: 'basic',
            from: 'col',
            selected: true,
          },
          {
            id: 2,
            name: 'Метки',
            baseName: 'mark',
            from: 'col',
            selected: false,
          },
        ],
        listTabsRow: [
          {
            id: 1,
            name: 'Основные',
            baseName: 'basic',
            from: 'row',
            selected: true,
          },
          {
            id: 2,
            name: 'Метки',
            baseName: 'mark',
            from: 'row',
            selected: false,
          },
        ],
        activeTab: {
          table: {
            basic: true,
            style: false,
            mark: false,
          },
          col: {
            basic: true,
            style: false,
            mark: false,
          },
          row: {
            basic: true,
            style: false,
            mark: false,
          },
        },
        curStepOnTable: 'table',
        modeEditCol: false,
        modeEditRow: false,
        tableTabsInfo: {
          styleCurTable: null,
          styleCurTableHeader: [],
          marksCurTable: null,
          marksCurTableHeader: [],
        },
        changeDragEvent: false,
        changeDragEventCol: false,
        changeDragEventRow: false,
      }
    },
    mounted () {
      this.getCategory()
    },
    methods: {
      addElem (positionObj) {
        if (positionObj === null) {
          this.showAddCategory = true
        }
        if (positionObj === 'category') {
          this.showAddTemplate = true
        }
        if (positionObj === 'template') {
          this.showAddTab = true
        }
        if (positionObj === 'tab') {
          this.showAddTable = true
        }
      },
      addCategory () {
        const objCategory = { title: this.newCategory.title }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('createNewReportCategory', objCategory).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.newCategory.title = null
          this.closeAddDialogs()
          this.getCategory()
        })
      },
      addTemplate () {
        const objTemplate = {
          plreportcategoryid: this.newTemplate.plReportCategoryId,
          title: this.newTemplate.title,
          sysname: this.newTemplate.sysName,
          description: this.newTemplate.description,
          flgactive: this.newTemplate.flgActive,
          forperiod: this.newTemplate.forPeriod,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('createNewReportTemplate', objTemplate).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.closeAddDialogs()
          this.getTemplateRequest(this.newTemplate.plReportCategoryId)
          // очищаем форму создания шаблона до дефолтных значений
          this.newTemplate.title = null
          this.newTemplate.sysName = null
          this.newTemplate.description = null
          this.newTemplate.flgActive = true
          this.newTemplate.forPeriod = null
        }).catch((error) => {
          console.error(error)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      addTab () {
        const objTab = {
          plreportid: this.newTab.plReportId,
          title: this.newTab.title,
          sortindex: this.newTab.sortIndex,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('createNewReportTab', objTab).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.getTabsRequest(this.newTab.plReportId)
          this.closeAddDialogs()
          // очищаем форму создания вкладки до дефолтных значений
          this.newTab.title = null
          this.newTab.sortIndex = null
        }).catch((error) => {
          console.error(error)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      addTable () {
        const objTable = {
          title: this.newTable.title,
          sysname: this.newTable.sysName,
          flgactive: this.newTable.flgActive,
          plreportid: this.newTable.plReportId,
          plreporttabid: this.newTable.plReportTabId,
          flgdynamicrows: this.newTable.flgDynamicRows,
          flgperiods: this.newTable.flgPeriods,
          flgrightsadvanced: this.newTable.flgRightSadvanced,
          sortindex: this.newTable.sortIndex,
          flgvisible: this.newTable.flgVisible,
          parenttableid: this.newTable.parentTableId,
          pllistid: this.newTable.plListId,
          headerheight: this.newTable.headerHeight,
          flgfilterdata: this.newTable.flgFilterData,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('createNewReportTable', objTable).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.getTableRequest(this.newTable.plReportTabId)
          this.closeAddDialogs()
          // очищаем форму создания таблицы до дефолтных значений
          this.newTable.title = null
          this.newTable.sysName = null
          this.newTable.flgActive = true
          this.newTable.plReportId = null
          this.newTable.plReportTabId = null
          this.newTable.flgDynamicRows = false
          this.newTable.flgPeriods = true
          this.newTable.flgRightSadvanced = false
          this.newTable.sortIndex = null
          this.newTable.flgVisible = true
          this.newTable.parentTableId = null
          this.newTable.plListId = null
          this.newTable.headerHeight = '40px'
          this.newTable.flgFilterData = null
        }).catch((error) => {
          console.error(error)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      editCategory (category) {
        const objUpdateCategory = { id: category.id, title: category.title }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('updateReportCategory', objUpdateCategory).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.getCategory()
        }).catch((e) => {
          console.error('Error:', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      editReports (report) {
        const objUpdateReport = {
          id: report.id,
          title: report.title,
          sysname: report.sysname,
          description: report.description,
          flgactive: report.flgactive,
          forperiod: report.forperiod,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('updateReportTemplate', objUpdateReport).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.getTemplateRequest(this.newTemplate.plReportCategoryId)
        }).catch((e) => {
          console.error('Error:', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      editTabs (tab) {
        const objUpdateTab = {
          id: tab.id,
          title: tab.title,
          sortindex: tab.sortindex,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('updatePlanningTabsList', objUpdateTab).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.getTabsRequest(this.newTab.plReportId)
        }).catch((e) => {
          console.error('Error:', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      closeAddDialogs () {
        this.showAddCategory = false
        this.showAddTemplate = false
        this.showAddTab = false
        this.showAddTable = false
      },
      getCategory () {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getPlanningCategories').then((resp) => {
          this.planningListCategories = resp
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error:', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      getTemplate (newval) {
        this.getTemplateRequest(newval.id)
        if (this.chosenCategory === newval.id) {
          // обнуляем нижестоящие
          this.curPosition.chosenCategory = null
          this.curPosition.chosenTemplate = null
          this.curPosition.chosenTab = null
          this.curPosition.chosenTable = null
          this.chosenCategory = null
          this.chosenTab = null
          this.chosenTable = null
          this.cleanListTabsActive()
          return
        }
        this.chosenCategory = newval.id
        this.curPosition.chosenCategory = newval
        // обнуляем нижестоящие
        this.curPosition.chosenTemplate = null
        this.curPosition.chosenTab = null
        this.curPosition.chosenTable = null
        this.chosenTab = null
        this.planningListTemplates = []
        this.$store.dispatch('setDataLoading', true)
        // заполняем id для модалки добавления новой категории
        this.newTemplate.plReportCategoryId = newval.id
      },
      getTemplateRequest (id) {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getPlanningReportsList', { plcategoryid: id }).then((resp) => {
          this.planningListTemplates = resp
          this.chooseTemplateInlist(resp[0])
          this.planningListTemplates.forEach(item => {
            this.$set(item, 'selected', false)
          })
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      getTabs (selectedTemplate) {
        this.planningListTabs = []
        this.getTabsRequest(selectedTemplate.id)
        if (this.chosenTab === selectedTemplate.title) {
          // обнуляем нижестоящие
          this.curPosition.chosenTemplate = null
          this.curPosition.chosenTab = null
          this.curPosition.chosenTable = null
          this.chosenTable = null
          this.chosenTab = null
          this.$nextTick(() => this.cleanTemplatesActive())
          return
        }
        this.cleanListTabsActive()
        if (!(this.chosenTab === selectedTemplate.title)) {
          this.$nextTick(() => this.cleanTemplatesActive())
        }
        this.chosenTab = selectedTemplate.title
        // запрос на получание табаов в этом шаблоне
        this.curPosition.chosenTemplate = selectedTemplate
        // обнуляем нижестоящие
        this.curPosition.chosenTab = null
        this.curPosition.chosenTable = null
        // заполняем id для модалки добавления нового таба
        this.newTab.plReportId = selectedTemplate.id
      },
      getTabsRequest (id) {
        this.$store.dispatch('setDataLoading', true)
        this.planningListTabs = []
        this.$store.dispatch('getPlanningTabsList', { pltemplateid: id }).then((resp) => {
          this.planningListTabs = resp.sort((a, b) => {
            return parseInt(a.sortindex) - parseInt(b.sortindex)
          })
          this.planningListTabs.forEach(item => {
            this.$set(item, 'selected', false)
          })
          this.chooseTabInlist(resp[0])
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      getTable (selectedTab) {
        this.$store.dispatch('setDataLoading', true)
        this.planningListTables = []
        this.getTableRequest(selectedTab.id)
        if (this.chosenTable === selectedTab.title) {
          // обнуляем нижестоящие
          this.curPosition.chosenTab = null
          this.curPosition.chosenTable = null
          this.chosenTable = null
          return
        }
        this.cleanListTabsActive()
        this.chosenTable = selectedTab.title
        this.curPosition.chosenTab = selectedTab
        // обнуляем нижестоящие
        this.curPosition.chosenTable = null
        this.newTable.plReportTabId = selectedTab.id
        this.newTable.plReportId = selectedTab.plreportid
      },
      getTableRequest (id) {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getPlanningTableList', { pltabid: id }).then((resp) => {
          this.planningListTables = resp.sort((a, b) => {
            return parseInt(a.sortindex) - parseInt(b.sortindex)
          })
          this.planningListTables.forEach(item => {
            this.$set(item, 'selected', false)
          })
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      getTableTemplateRequest () {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getTableTemplate', this.planningTables.id).then((res) => {
          this.curTableInfo = res
          this.curTableInfo.row = this.curTableInfo.row.sort((a, b) => {
            return parseInt(a.sortindex) - parseInt(b.sortindex)
          })
          this.curTableInfo.col = this.curTableInfo.col.sort((a, b) => {
            return parseInt(a.sortindex) - parseInt(b.sortindex)
          })
          this.curTableInfoCopy = JSON.parse(JSON.stringify(this.curTableInfo))
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      // getStylesTable (tableId) {
      //   this.$store.dispatch('setDataLoading', true)
      //   const deleteElHeader = ['id', 'pltableid', 'style', 'description']
      //   setTimeout(() => {
      //     this.$store.dispatch('getStylesTableInfo', tableId).then((res) => {
      //       // если в данной таблице нет стилей, добавляем пустой элемент, что бы на основе его можно было создать стиль
      //       if (!res.length) {
      //         res.push({
      //           plrowid: null,
      //           plcolid: null,
      //           pltableid: this.curTableInfo.info.id,
      //           stylesid: null,
      //           priority: null,
      //           can_edit: null,
      //           flgheader: 0,
      //           flg_presentation: 0,
      //         })
      //         this.tableTabsInfo.styleCurTableHeader = Object.keys(res[0])
      //         this.tableTabsInfo.styleCurTableHeader = this.tableTabsInfo.styleCurTableHeader.filter(item => !deleteElHeader.includes(item))
      //         this.tableTabsInfo.styleCurTable = []
      //         return
      //       }
      //       this.tableTabsInfo.styleCurTable = res.sort((a, b) => a.priority - b.priority)
      //       this.tableTabsInfo.styleCurTableHeader = Object.keys(this.tableTabsInfo.styleCurTable[0])
      //       this.tableTabsInfo.styleCurTableHeader = this.tableTabsInfo.styleCurTableHeader.filter(item => !deleteElHeader.includes(item))
      //     }).catch((e) => {
      //       console.error('Error: ', e)
      //       this.$store.dispatch('setDataLoading', false)
      //     })
      //   }, 1000)
      // },
      // getMarksTable (tableId) {
      //   this.$store.dispatch('setDataLoading', true)
      //   const deleteElHeader = ['id', 'pltableid', 'style', 'description']
      //   setTimeout(() => {
      //     this.$store.dispatch('getMarkTable', tableId).then((res) => {
      //       this.tableTabsInfo.marksCurTable = res.sort((a, b) => a.priority - b.priority)
      //       // если в данной таблице нет меток, добавляем пустой элемент, что бы на основе его можно было создать метку
      //       if (!res.length) {
      //         res.push({
      //           categoryid: null,
      //           pltableid: this.curTableInfo.info.id,
      //           plrowid: null,
      //           plcolid: null,
      //           value_int: null,
      //           value_str: null,
      //           title: null,
      //         })
      //         this.tableTabsInfo.marksCurTableHeader = Object.keys(res[0])
      //         this.tableTabsInfo.marksCurTableHeader = this.tableTabsInfo.marksCurTableHeader.filter(item => !deleteElHeader.includes(item))
      //         this.tableTabsInfo.marksCurTable = []
      //         return
      //       }
      //       this.tableTabsInfo.marksCurTableHeader = Object.keys(this.tableTabsInfo.marksCurTable[0])
      //       this.tableTabsInfo.marksCurTableHeader = this.tableTabsInfo.marksCurTableHeader.filter(item => !deleteElHeader.includes(item))
      //       this.$store.dispatch('setDataLoading', false)
      //     }).catch((e) => {
      //       console.error('Error: ', e)
      //       this.$store.dispatch('setDataLoading', false)
      //     })
      //   }, 1000)
      // },
      selectTable (table) {
        this.curPosition.chosenTable = table
        this.planningTables = {
          id: table.id,
          title: table.title,
          sysName: table.sysname,
          flgActive: table.flgactive,
          plReportId: table.plreportid,
          plReportTabId: table.plreporttabid,
          flgDynamicRows: table.flgdynamicrows,
          flgPeriods: table.flgperiods,
          flgRightSadvanced: table.flgrightsadvanced,
          sortIndex: table.sortindex,
          flgVisible: table.flgvisible,
          parentTableId: table.parenttableid,
          plListId: table.pllistid,
          headerHeight: table.headerheight,
          flgFilterData: table.flgfitlerdata, // баг в названии в базе
        }
        this.getTableTemplateRequest()
        // this.getStylesTable(table.id)
        // this.getMarksTable(table.id)
        this.changedCol.plTableId = table.id
        this.changedRow.plTableId = table.id
      },
      cleanTemplatesActive () {
        if (this.planningListTemplates.length) {
          this.planningListTemplates.forEach(item => {
            item.selected = false
          })
        }
      },
      cleanListTabsActive () {
        if (this.planningListTabs.length) {
          this.planningListTabs.forEach(item => {
            item.selected = false
          })
        }
      },
      validationSysName (event, el) {
        if (!event) {
          return
        }
        const regex = /^[a-zA-Z0-9_]+$/
        if (!regex.test(event)) {
          this.$nextTick(() => {
            el.sysName = event.slice(0, -1)
          })
          return
        }
        el.sysName = el.sysName.toUpperCase()
      },
      chooseTemplateInlist (elem) {
        this.$store.commit('setBuilderTemplatePanelSettingsOpen', true)
        this.chosenTemplateInlist = elem !== undefined ? JSON.parse(JSON.stringify(elem)) : {}
      },
      chooseTabInlist (elem) {
        this.$store.commit('setBuilderTemplatePanelSettingsOpen', true)
        this.chosenTabInlist = JSON.parse(JSON.stringify(elem))
      },
      dragEventsCancel () {
        this.changeDragEvent = false
        this.changeDragEventCol = false
        this.changeDragEventRow = false
      },
      closeSettingsInList () {
        this.chosenCategoryInlist = null
        this.chosenTemplateInlist = null
        this.chosenTabInlist = null
      },
    },
    computed: {
      ...mapGetters(['tableStyles', 'builderTemplatePanelSettingsOpen']),
      nameStep () {
        const textStep = {
          table: this.curPosition?.chosenTable?.title,
          tab: this.curPosition?.chosenTab?.title,
          template: this.curPosition?.chosenTemplate?.title,
          category: this.curPosition?.chosenCategory?.title,
        }
        return textStep[this.curStep] || ''
      },
      buttonAddName () {
        const textButton = {
          table: 'ниче',
          tab: 'Таблицу',
          template: 'Вкладку',
          category: 'Шаблон',
        }
        return textButton[this.curStep] || ''
      },
      dataLoading () {
        return this.$store.getters.loading
      },
    },
    watch: {
      curPosition: {
        handler () {
          this.closeSettingsInList()
          this.choiceColActive = null
          this.choiceRowActive = null
          this.dragEventsCancel()
          let step = null
          if (this.curPosition.chosenCategory && this.curPosition.chosenTemplate && this.curPosition.chosenTab && this.curPosition.chosenTable) {
            step = 'table'
          } else if (this.curPosition.chosenCategory && this.curPosition.chosenTemplate && this.curPosition.chosenTab) {
            step = 'tab'
          } else if (this.curPosition.chosenCategory && this.curPosition.chosenTemplate) {
            step = 'template'
          } else if (this.curPosition.chosenCategory) {
            step = 'category'
          }
          this.curStep = step
        },
        deep: true,
      },
    },
  }
</script>

<style scoped lang="scss">
.title-step {
  display: block;
  position: absolute;
  top: 20px;
  left: 57%;
  transform: translate(-50%, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 50px;
}
.v-list-item--link {
  padding-left: 0 !important;
}
.v-list-group {
  ::v-deep .v-list-group__header__prepend-icon {
    margin-right: 10px;
  }
  .v-list-group {
    ::v-deep .v-list-group__header__prepend-icon {
      margin-right: 0;
    }
    ::v-deep .v-list-group__header {
      padding-left: 17px !important;
    }
    .v-list-group {
      ::v-deep .v-list-group__header {
        padding-left: 43px !important;
      }
      .v-list-item {
        padding-left: 94px !important;
        ::v-deep .v-list-item__icon {
          margin-right: 10px;
        }
      }
    }
  }
}
.v-list-group__items {
  .v-list-group {
    padding-left: 0 !important;
    .v-list-group__header {
      padding-left: 0 !important;
    }
  }
  .v-list-item__icon {
    margin-right: 10px;
  }
}

.main-area {
  position: sticky;
  top: 20px;
}
</style>
