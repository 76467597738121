<template>
  <v-card
    class="px-6 py-6 my-0"
  >
    <v-row class="header-fixed">
      <v-col>
        <h3 class="mb-2">{{ name }}</h3>
      </v-col>
    </v-row>
    <div class="card-wrapper">
      <v-list class="drag-list">
        <draggable
          v-model="listCopy"
          draggable=".test"
          tag="div"
          @change="onMove"
        >
          <v-list-item
            v-for="(item, index) in listCopy"
            :key="`categories${index}`"
            :value="item"
            class="list-item mx-1 my-1 px-2 d-flex justify-space-between"
            :class="[
              { 'background-active' : item.id === chosenElementInList?.id},
              { 'test' : canDrag },
            ]"
            color="primary"
            @click="chooseCategoryInlist(item)"
          >
            {{ item.title }} ({{ item.id }})
            <div class="d-flex justify-space-between">
              <v-btn
                v-if="canDel"
                icon
                tile
                text
                small
                class="pl-0"
                @click.stop="delElTemplate(item.id, 'category')"
              >
                <v-icon>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </div>
          </v-list-item>
        </draggable>
        <v-col
          v-if="changeDragEvent"
          class="d-flex justify-center px-0"
        >
          <v-btn
            color="primary"
            class="mx-0"
            @click="changeSortIndex()"
          >
            Сохранить перестановку
          </v-btn>
        </v-col>
      </v-list>
      <div
        v-if="sidePanel && chosenElementInList"
        class="settings-panel__wrapper"
        :class="{ 'arrow-rotate' : !builderTemplatePanelSettingsOpen }"
      >
        <v-form
          v-if="chosenElementInList && builderTemplatePanelSettingsOpen"
          lazy-validation
        >
          <v-card
            class="settings-panel py-6"
            :class="[{ 'px-0' : !builderTemplatePanelSettingsOpen }, { 'px-6' : builderTemplatePanelSettingsOpen }]"
          >
            <div class="text-center">
              <h2 class="text-h3 my-2">{{ chosenElementInList.title }}</h2>
            </div>
            <form-body
              v-if="Object.keys(chosenElementInList).length !== 0 && builderTemplatePanelSettingsOpen"
              :selected-fields-on-list="selectedFieldsOnList"
              :chosen-element-in-list="chosenElementInList"
              edit-button
              @editElement="editElement"
            />
          </v-card>
        </v-form>
        <div
          class="arrow-settings-panel"
          @click="togglePanel"
        />
      </div>
    </div>
    <mdialog ref="dlgDel" />
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import draggable from 'vuedraggable'
  import mdialog from '../../../../components/mdialog.vue'
  import formBody from './builderTemplateFormBody.vue'

  export default {
    name: 'builderTemplateList',
    components: {
      draggable,
      mdialog,
      formBody,
    },
    props: {
      list: {
        type: Array,
        default: () => [],
      },
      name: {
        type: String,
        default: '',
        required: true,
      },
      selectedFieldsOnList: {
        type: Array,
        default: () => [],
      },
      sidePanel: {
        type: Boolean,
        default: true,
      },
      canDrag: {
        type: Boolean,
        default: false,
      },
      canDel: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        chosenElementInList: null,
        listCopy: [],
        changeDragEvent: false,
      }
    },
    methods: {
      chooseCategoryInlist (elem) {
        this.$store.commit('setBuilderTemplatePanelSettingsOpen', true)
        this.chosenElementInList = JSON.parse(JSON.stringify(elem))
      },
      togglePanel () {
        this.$store.commit('setBuilderTemplatePanelSettingsOpen', !this.builderTemplatePanelSettingsOpen)
      },
      delElTemplate (id, type) {
        this.$refs.dlgDel.open({
          title: 'Удалить объект?',
          acceptText: 'Продолжить',
          cancelText: 'Отмена',
          dialogMaxWidth: 440,
        }).then(() => {
          if (type === 'category') {
          }
          if (type === 'col') {
            const payload = {
              id: id,
            }
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('delReportTableCol', payload).then(() => {
              this.getTableTemplateRequest()
            }).catch((e) => {
              console.error('Error: ', e)
              this.$store.dispatch('setDataLoading', false)
            })
          }
          if (type === 'row') {
            const payload = {
              id: id,
            }
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('delReportTableRow', payload).then(() => {
              this.getTableTemplateRequest()
            }).catch((e) => {
              console.error('Error: ', e)
              this.$store.dispatch('setDataLoading', false)
            })
          }
        })
      },
      editElement (el) {
        this.$emit('editElement', el)
      },
      onMove () {
        this.changeDragEvent = true
      },
      async changeSortIndex () {
        this.$store.dispatch('setDataLoading', true)
        if (this.name === 'Вкладки') {
          const maxSortIndex = Math.max(...this.listCopy.map(item => parseInt(item.sortindex)))
          let newSortIndexStr = ''
          await this.listCopy.forEach((item, index) => {
            item.sortindex = index + maxSortIndex + 1
            newSortIndexStr += `${item.id}${index !== this.listCopy.length - 1 ? `,${index + maxSortIndex + 1}^${index},` : `,${index + maxSortIndex + 1}^${index}`}` // Делает строку типа - 64,46^0,65,47^1,63,48^2
          })
          const payload = { data: newSortIndexStr }
          this.$store.dispatch('updatePlaningTabsSortindex', payload).then(() => {
            // this.getTabsRequest(this.newTab.plReportId)
            this.$store.dispatch('setDataLoading', false)
          }).catch((e) => {
            console.error('Error: ', e)
            this.$store.dispatch('setDataLoading', false)
          })
        }
        if (this.name === 'Таблицы') {
          const maxSortIndex = Math.max(...this.listCopy.map(item => parseInt(item.sortindex)))
          let newSortIndexStr = ''
          await this.listCopy.forEach((item, index) => {
            item.sortindex = index + maxSortIndex + 1
            newSortIndexStr += `${item.id}${index !== this.listCopy.length - 1 ? `,${index + maxSortIndex + 1}^${index},` : `,${index + maxSortIndex + 1}^${index}`}` // Делает строку типа - 64,46^0,65,47^1,63,48^2
          })
          const payload = { data: newSortIndexStr }
          this.$store.dispatch('updatePlanningTableSortindex', payload).then(() => {
            this.$store.dispatch('setDataLoading', false)
            // this.getCategory()
          }).catch((e) => {
            console.error('Error: ', e)
            this.$store.dispatch('setDataLoading', false)
          })
        }
      },
    },
    computed: {
      ...mapGetters(['builderTemplatePanelSettingsOpen']),
    },
    watch: {
      list: {
        handler () {
          if (this.list.length) {
            this.chooseCategoryInlist(this.list[0])
            this.listCopy = JSON.parse(JSON.stringify(this.list))
          }
        },
        immediate: true,
      },
    },
  }
</script>

<style scoped lang="scss">
.card-wrapper {
  display: flex;
  justify-content: space-between;
}
.drag-list {
  .theme--light.v-list-item:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
  }
  .v-list-item {
    &:after {
      display: none;
      content: none !important;
    }
  }
  .list-item {
    gap: 30px;
  }
}
.settings-panel__wrapper {
  position: relative;
  height: fit-content;
  .settings-panel {
    width: 550px;
    margin: 0;
    height: auto;
    max-height: 85vh;
    overflow-y: scroll;
  }
  .arrow-settings-panel {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translate(0, -50%) rotate(180deg);
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23231f20;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EWondicon - UI (Free)%3C/title%3E%3Cpath class='cls-1' d='M117.86,140.5a5,5,0,0,1-3.53-1.46L78.6,103.32a5,5,0,0,1,0-7.07l35.73-35.73a5,5,0,1,1,7.07,7.07L89.21,99.78,121.4,132a5,5,0,0,1-3.54,8.53Z'/%3E%3Cpath class='cls-1' d='M154.29,175.69H46a21.57,21.57,0,0,1-21.54-21.54V45.85A21.57,21.57,0,0,1,46,24.31H154.29a21.57,21.57,0,0,1,21.54,21.54v108.3A21.57,21.57,0,0,1,154.29,175.69ZM46,34.31A11.55,11.55,0,0,0,34.46,45.85v108.3A11.55,11.55,0,0,0,46,165.69H154.29a11.55,11.55,0,0,0,11.54-11.54V45.85a11.55,11.55,0,0,0-11.54-11.54Z'/%3E%3C/svg%3E");
  }
}
.arrow-rotate {
  height: auto;
  .arrow-settings-panel {
    transform: translate(0, -50%) rotate(0);
  }
  .settings-panel {
    width: auto;
    height: 100%;
    max-height: none;
  }
}
.background-active {
  background-color: rgba(0, 0, 0, 0.04);
}
</style>
