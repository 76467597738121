<template>
  <div>
    <v-col class="py-0">
      <v-card
        class="mt-0 px-6 py-6"
      >
        <v-btn
          color="success"
          @click="showTableSettingsPanel"
        >
          Таблица
        </v-btn>
        <v-btn
          color="success"
          @click="showColSettingsPanel"
        >
          Добавить столбец
        </v-btn>
        <v-btn
          color="success"
          @click="showRowSettingsPanel"
        >
          Добавить строку
          {{choiceRowActiveFromSettings}}
        </v-btn>
      </v-card>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th
                v-for="(col, index) in tableInfoCopy?.col"
                :key="'col'+ index"
                :class="{ 'hover-border-header': hoverCol === index || choiceColActive === index || choiceColActiveFromSettings === col.id }"
                :style="cellStyle(tableTabsInfo.styleCurTable, col.id, null, true)"
              >
                {{index.id}}
                <div
                  class="header-settings-elem"
                  @click="settingsColOpen(col, index)"
                  @mouseenter="hoverCol = index"
                  @mouseleave="hoverCol = null"
                >
                  <v-icon>
                    mdi-cog
                  </v-icon>
                </div>
                <div>
                  {{ col.title ? col.title : col.description }} ({{ col.id }})
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, indexRow) in tableInfoCopy?.row"
              :key="'row' + row.id"
              :class="{ 'hover-border': hoverRow === indexRow || choiceRowActive === indexRow || choiceRowActiveFromSettings === row.id }"
            >
              <td
                v-for="(col, indexCol) in tableInfoCopy?.col"
                :key="'column' + indexCol + col.id"
                :class="[
                  { 'hover-border-col': hoverCol === indexCol || choiceColActive === indexCol || choiceColActiveFromSettings === col.id },
                  { 'hover-border': cellActive?.colId === col.id && cellActive?.rowId === row.id }
                ]"
                :style="cellStyle(tableTabsInfo.styleCurTable, col.id, row.id, false)"
                class="td-body-table"
              >
                <div v-if="indexCol === 0">
                  {{ row.title }} ({{ row.id }})
                </div>
                <div v-else>
                  Пример
                </div>
                <div
                  class="cell-settings-elem"
                >
                  <v-checkbox
                    v-model="cellEditState[`${col.id}+${row.id}`]"
                    class="cell-checkbox"
                  />
                  <v-icon
                    @click="settingsCellOpen(col, row)"
                  >
                    mdi-cog
                  </v-icon>
                </div>
              </td>
              <td
                class="settings"
              >
                <div
                  @click="settingsRowOpen(row, indexRow)"
                  @mouseenter="hoverRow = indexRow"
                  @mouseleave="hoverRow = null"
                >
                  <v-icon>
                    mdi-cog
                  </v-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <v-col class="arrow-col-block d-flex px-0 py-0">
          <v-col
            class="d-flex flex-row px-0 py-0"
            :style="choiceColActive || choiceColActive === 0 ? '' : 'visibility: hidden;'"
          >
            <v-btn
              dark
              icon
              :disabled="choiceColActive === 0"
              :color="'blue-grey lighten-3'"
              @click="changeColPosition('left')"
            >
              <v-icon
                large
                :style="choiceColActive === 0 ? 'color: rgba(1, 0, 0, 0.1) !important;' : ''"
              >
                mdi-arrow-left-bold
              </v-icon>
            </v-btn>
            <v-btn
              dark
              icon
              :disabled="choiceColActive === tableInfoCopy?.col?.length - 1"
              :color="'blue-grey lighten-3'"
              @click="changeColPosition('right')"
            >
              <v-icon
                large
                :style="choiceColActive === tableInfoCopy?.col?.length - 1 ? 'color: rgba(1, 0, 0, 0.1) !important;' : ''"
              >
                mdi-arrow-right-bold
              </v-icon>
            </v-btn>
          </v-col>
          <v-col class="px-0 py-0">
            <v-tooltip
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  :color="'blue-grey lighten-3'"
                  :dark="changeDragEventRow || changeDragEventCol"
                  :disabled="!(changeDragEventRow || changeDragEventCol)"
                  v-bind="attrs"
                  icon
                  text
                  v-on="on"
                  @click="cancelMoveChanges"
                >
                  <v-icon
                    large
                    :style="changeDragEventRow || changeDragEventCol ? '' : 'color: rgba(0, 0, 0, 0.1) !important;'"
                  >
                    mdi-history
                  </v-icon>
                </v-btn>
              </template>
              <span>Отменить перестановки</span>
            </v-tooltip>
          </v-col>
        </v-col>
        <v-col
          v-if="choiceRowActive || choiceRowActive === 0"
          class="arrow-row-block d-flex flex-row px-0 py-0"
        >
          <v-btn
            dark
            icon
            :disabled="choiceRowActive === 0"
            :color="'blue-grey lighten-3'"
            @click="changeRowPosition('top')"
          >
            <v-icon
              large
              :style="choiceRowActive === 0 ? 'color: rgba(1, 0, 0, 0.1) !important;' : ''"
            >
              mdi-arrow-left-bold
            </v-icon>
          </v-btn>
          <v-btn
            dark
            icon
            :disabled="choiceRowActive + 1 === tableInfoCopy?.row?.length"
            :color="'blue-grey lighten-3'"
            @click="changeRowPosition('bottom')"
          >
            <v-icon
              large
              :style="choiceRowActive + 1 === tableInfoCopy?.row?.length ? 'color: rgba(1, 0, 0, 0.1) !important;' : ''"
            >
              mdi-arrow-right-bold
            </v-icon>
          </v-btn>
        </v-col>
      </div>
      <v-col
        v-if="changeDragEventRow || changeDragEventCol"
        class="d-flex justify-center px-0 gap-10"
      >
        <v-btn
          color="primary"
          class="mx-0"
          @click="changeSortIndex('row/col')"
        >
          Сохранить перестановку
        </v-btn>
      </v-col>
    </v-col>
    <div
      class="settings-panel__wrapper"
      :class="{ 'arrow-rotate' : !builderTemplatePanelSettingsOpen }"
    >
      <v-card
        class="settings-panel py-6"
        :class="[{ 'px-0' : !builderTemplatePanelSettingsOpen }, { 'px-6' : builderTemplatePanelSettingsOpen }]"
      >
        <v-form
          v-if="curStepOnTable==='table' && builderTemplatePanelSettingsOpen"
          ref="form"
          lazy-validation
        >
          <div class="text-center">
            <h2 class="text-h3 my-2">Таблица</h2>
          </div>
          <v-slide-group
            show-arrows
            class="pa-1 sticky-2"
            bg-color="deep-purple-darken-4"
            center-active
          >
            <v-slide-item
              v-for="(item, index) in listTabsTables"
              :key="`tabs${index}`"
            >
              <v-btn
                class="mx-1 my-1 px-2"
                outlined
                small
                :color="item.selected ? 'green' : 'gray'"
                @click="activeTabTable(listTabsTables, item)"
              >
                {{item.name}}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
          <form-body
            v-if="activeTab.table.basic && Object.keys(planningTables).length !== 0 && builderTemplatePanelSettingsOpen"
            :selected-fields-on-list="tableItemsList"
            :chosen-element-in-list="planningTables"
            edit-button
            @editElement="editTable"
          />
          <v-row
            v-if="activeTab.table.style"
          >
            <v-col>
              <table-settings
                :header="tableTabsInfo.styleCurTableHeader"
                :body="tableTabsInfo.styleCurTable"
                :table-date="tableInfoCopy"
                :type="'style'"
                @choiceRow="choiceRowInTableSettings"
                @updateSettingsTableInTable="updateSettingsTableInTable"
                @delElement="delElementSettings"
                @changeStylePresentationMode="changeStylePresentationMode"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="activeTab.table.mark"
          >
            <v-col>
              <table-settings
                :header="tableTabsInfo.marksCurTableCopyHeader"
                :body="tableTabsInfo.marksCurTableCopy"
                :table-date="tableInfoCopy"
                :type="'mark'"
                @choiceRow="choiceRowInTableSettings"
                @updateSettingsTableInTable="updateSettingsTableInTable"
                @delElement="delElementSettings"
                @changeStylePresentationMode="changeStylePresentationMode"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="activeTab.table.queriesDate"
          >
            <v-col>
              <request
                :requests="queriesByTable"
                :selected-table-elements="selectedTableElements"
                :list-tabs-tables="listTabsTables"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="activeTab.table.queriesDynamic"
          >
            <v-col>
              <request
                :requests="queriesByTable"
                :selected-table-elements="selectedTableElements"
                :list-tabs-tables="listTabsTables"
                :query-type-dynamic="true"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-form
          v-if="curStepOnTable === 'col' && builderTemplatePanelSettingsOpen"
          ref="form"
          lazy-validation
        >
          <div class="text-center">
            <h2 class="text-h3 my-2">Столбец</h2>
          </div>
          <v-slide-group
            show-arrows
            class="pa-1 sticky-2"
            bg-color="deep-purple-darken-4"
            center-active
          >
            <v-slide-item
              v-for="(item, index) in listTabsCol"
              v-show="!modeEditCol ? item.baseName === 'basic' : true"
              :key="`tabs${index}`"
            >
              <v-btn
                class="mx-1 my-1 px-2"
                outlined
                small
                :color="item.selected ? 'green' : 'gray'"
                @click="activeTabTable(listTabsCol, item)"
              >
                {{item.name}}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
          <form-body
            v-if="activeTab.col.basic && Object.keys(changedCol).length !== 0 && builderTemplatePanelSettingsOpen"
            :selected-fields-on-list="colItemsList"
            :chosen-element-in-list="changedCol"
            :add-button="!modeEditCol"
            :edit-button="modeEditCol"
            :del-button="modeEditCol"
            :type="'col'"
            @addElement="addCol"
            @editElement="editCol"
            @delElement="delElTemplate"
          />
          <v-row
            v-if="activeTab.col.mark && tableTabsInfo?.marksCurTable.length"
          >
            <v-col>
              <table-settings
                :header="tableTabsInfo.marksCurTableCopyHeader"
                :body="tableTabsInfo.marksCurTableCopy"
                :table-date="tableInfoCopy"
                :type="'mark'"
                :selected-table-elements="selectedTableElements"
                @choiceRow="choiceRowInTableSettings"
                @updateSettingsTableInTable="updateSettingsTableInTable"
                @delElement="delElementSettings"
                @changeStylePresentationMode="changeStylePresentationMode"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="activeTab.col.request"
          >
            <v-col>
              <request
                :requests="queriesByTable"
                :selected-table-elements="selectedTableElements"
                :list-tabs-tables="listTabsTables"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-form
          v-if="curStepOnTable === 'row' && builderTemplatePanelSettingsOpen"
          ref="form"
          lazy-validation
        >
          <div class="text-center">
            <h2 class="text-h3 my-2">Строка</h2>
          </div>
          <v-slide-group
            show-arrows
            class="pa-1 sticky-2"
            bg-color="deep-purple-darken-4"
            center-active
          >
            <v-slide-item
              v-for="(item, index) in listTabsRow"
              v-show="!modeEditRow ? item.baseName === 'basic' : true"
              :key="`tabs${index}`"
            >
              <v-btn
                class="mx-1 my-1 px-2"
                outlined
                small
                :color="item.selected ? 'green' : 'gray'"
                @click="activeTabTable(listTabsRow, item)"
              >
                {{item.name}}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
          <form-body
            v-if="activeTab.row.basic && Object.keys(changedRow).length !== 0 && builderTemplatePanelSettingsOpen"
            :selected-fields-on-list="rowItemsList"
            :chosen-element-in-list="changedRow"
            :add-button="!modeEditRow"
            :edit-button="modeEditRow"
            :del-button="modeEditRow"
            :type="'row'"
            @addElement="addRow"
            @editElement="editRow"
            @delElement="delElTemplate"
          />
          <v-row
            v-if="activeTab.row.mark && tableTabsInfo?.marksCurTable.length"
          >
            <v-col>
              <table-settings
                :header="tableTabsInfo.marksCurTableCopyHeader"
                :body="tableTabsInfo.marksCurTableCopy"
                :table-date="tableInfoCopy"
                :type="'mark'"
                :selected-table-elements="selectedTableElements"
                @choiceRow="choiceRowInTableSettings"
                @updateSettingsTableInTable="updateSettingsTableInTable"
                @delElement="delElementSettings"
                @changeStylePresentationMode="changeStylePresentationMode"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="activeTab.row.request"
          >
            <v-col>
              <request
                :requests="queriesByTable"
                :selected-table-elements="selectedTableElements"
                :list-tabs-tables="listTabsTables"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-form
          v-if="curStepOnTable === 'cell' && builderTemplatePanelSettingsOpen"
          ref="form"
          lazy-validation
        >
          <div class="text-center">
            <h2 class="text-h3 my-2">Ячейка</h2>
          </div>
          <v-slide-group
            show-arrows
            class="pa-1 sticky-2"
            bg-color="deep-purple-darken-4"
            center-active
          >
            <v-slide-item
              v-for="(item, index) in listTabsCell"
              :key="`tabs${index}`"
            >
              <v-btn
                class="mx-1 my-1 px-2"
                outlined
                small
                :color="item.selected ? 'green' : 'gray'"
                @click="activeTabTable(listTabsCell, item)"
              >
                {{item.name}}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
          <v-row v-if="activeTab.cell.mark">
            <v-col>
              <table-settings
                :header="tableTabsInfo.marksCurTableCopyHeader"
                :body="tableTabsInfo.marksCurTableCopy"
                :table-date="tableInfoCopy"
                :type="'mark'"
                :selected-table-elements="selectedTableElements"
                @choiceRow="choiceRowInTableSettings"
                @updateSettingsTableInTable="updateSettingsTableInTable"
                @delElement="delElementSettings"
                @changeStylePresentationMode="changeStylePresentationMode"
              />
            </v-col>
          </v-row>
          <v-row v-if="activeTab.cell.request">
            <request
              :requests="queriesByTable"
              :selected-table-elements="selectedTableElements"
              :list-tabs-tables="listTabsTables"
            />
          </v-row>
        </v-form>
      </v-card>
      <div
        class="arrow-settings-panel"
        @click="togglePanel"
      />
      <mdialog ref="dlgDel" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import tableSettings from './builderTemplateSettingsTableInTable.vue'
  import formBody from './builderTemplateFormBody.vue'
  import request from './builderTemplateRequest.vue'
  import mdialog from '../../../../components/mdialog.vue'
  const staticData = {
    TOOLTIP_COL: '<p>маска ввода данных в строку</p>\n' +
      '          <p>null - определяется настройкой строки</p>\n' +
      '          <p>формат маски: <span>Формат маски: &#35;&lt;представление&gt;&lt;модификатор&gt;&lt;точность&gt;</span></p>\n' +
      '          <p>\n' +
      '            представление:\n' +
      '            i - в виде целого числа,\n' +
      '            f - в виде дробного числа,\n' +
      '            p - в виде процентов,\n' +
      '            d - в виде даты\n' +
      '          </p>\n' +
      '          <p>\n' +
      '            модификатор:\n' +
      '            nd - пустое значение заменяем на прочерк "-"\n' +
      '            nn - аналогично nd -- к удалению\n' +
      '            nz - пустое значение заменяем на 0\n' +
      '            zn - значение 0 заменяем на пусто\n' +
      '            ze - аналогично zn -- к удалению\n' +
      '            zd - значение 0 заменяем на прочерк "-"\n' +
      '            если модификатор не указан, возвращаем как есть\n' +
      '          </p>\n' +
      '          <p>\n' +
      '            точность: количество знаков после запятой, если не указано, считается 0. Для представлений i и d не учитывается.\n' +
      '          </p>',
    TOOLTIP_ROW: '<p>null - определяется настройкой столбца</p>\n' +
      '                  <p>формат маски: <span>Формат маски: &#35;&lt;представление&gt;&lt;модификатор&gt;&lt;точность&gt;</span></p>\n' +
      '                  <p>\n' +
      '                    представление:\n' +
      '                    i - в виде целого числа,\n' +
      '                    f - в виде дробного числа,\n' +
      '                    p - в виде процентов,\n' +
      '                    d - в виде даты\n' +
      '                  </p>\n' +
      '                  <p>\n' +
      '                    модификатор:\n' +
      '                    nd - пустое значение заменяем на прочерк "-"\n' +
      '                    nn - аналогично nd -- к удалению\n' +
      '                    nz - пустое значение заменяем на 0\n' +
      '                    zn - значение 0 заменяем на пусто\n' +
      '                    ze - аналогично zn -- к удалению\n' +
      '                    zd - значение 0 заменяем на прочерк "-"\n' +
      '                    если модификатор не указан, возвращаем как есть\n' +
      '                  </p>\n' +
      '                  <p>\n' +
      '                    точность: количество знаков после запятой, если не указано, считается 0. Для представлений i и d не учитывается.\n' +
      '                  </p>',
  }

  export default {
    name: 'builderTemplateTable',
    components: {
      tableSettings,
      formBody,
      request,
      mdialog,
    },
    props: {
      tableInfo: {
        type: Object,
        default: () => {},
        required: true,
      },
      planningListTables: {
        type: Object,
        default: () => {},
        required: true,
      },
    },
    data () {
      return {
        tableInfoCopy: {},
        choiceColActive: null,
        stylePresentationModeActive: false,
        planningTables: {
          id: null,
          title: null,
          sysName: null,
          flgActive: true,
          plReportId: null,
          plReportTabId: null,
          flgDynamicRows: false,
          flgPeriods: true,
          flgRightSadvanced: false,
          sortIndex: null,
          flgVisible: true,
          parentTableId: null,
          plListId: null,
          headerHeight: '40px',
          flgFilterData: null,
        },
        tableTabsInfo: {
          styleCurTable: null,
          styleCurTableHeader: [],
          marksCurTable: null,
          marksCurTableHeader: [],
        },
        activeTab: {
          table: {
            basic: true,
            style: false,
            mark: false,
          },
          col: {
            basic: true,
            style: false,
            mark: false,
            request: false,
          },
          row: {
            basic: true,
            style: false,
            mark: false,
            request: false,
          },
          cell: {
            mark: true,
            request: false,
          },
        },
        hoverRow: null,
        hoverCol: null,
        choiceRowActive: null,
        choiceColActiveFromSettings: null,
        choiceRowActiveFromSettings: null,
        changeDragEvent: false,
        changeDragEventCol: false,
        changeDragEventRow: false,
        changedCol: {
          id: null,
          title: null,
          sysName: null,
          flgActive: true,
          plTableId: null,
          plListId: null,
          description: null,
          dataMask: null,
          dataType: null,
          dataPeriodShift: null,
          sortIndex: null,
          flgVisible: true,
          colType: 0,
          periodIcColsCount: null,
          plGroupId: null,
          width: null,
          plCalcGroupId: null,
          flgPresentVisible: true,
        },
        changedRow: {
          id: null,
          title: null,
          sysName: null,
          flgActive: true,
          description: null,
          plTableId: null,
          plGroupId: null,
          dataMask: null,
          dataType: null,
          dataPeriodShift: null,
          sortIndex: null,
          flgVisible: true,
          flgNumerated: false,
          flgDynamic: false,
          height: '40px',
          plCalcGroupId: null,
          flgPresentVisible: true,
          plGroupParentRowId: null,
          flgColLapsed: null,
        },
        changedRowDefault: {
          id: null,
          title: null,
          sysName: null,
          flgActive: true,
          description: null,
          plTableId: null,
          plGroupId: null,
          dataMask: null,
          dataType: null,
          dataPeriodShift: null,
          sortIndex: null,
          flgVisible: true,
          flgNumerated: false,
          flgDynamic: false,
          height: '40px',
          plCalcGroupId: null,
          flgPresentVisible: true,
          plGroupParentRowId: null,
          flgColLapsed: null,
        },
        curStepOnTable: 'table',
        listTabsTables: [
          {
            id: 1,
            name: 'Основные',
            baseName: 'basic',
            from: 'table',
            selected: true,
          },
          {
            id: 2,
            name: 'Стили',
            baseName: 'style',
            from: 'table',
            selected: false,
          },
          {
            id: 3,
            name: 'Метки',
            baseName: 'mark',
            from: 'table',
            selected: false,
          },
          {
            id: 4,
            name: 'Запрос (Данные)',
            baseName: 'queriesDate',
            from: 'table',
            selected: false,
          },
          {
            id: 5,
            name: 'Запрос (Dynamic)',
            baseName: 'queriesDynamic',
            from: 'table',
            selected: false,
          },
        ],
        listTabsCol: [
          {
            id: 1,
            name: 'Основные',
            baseName: 'basic',
            from: 'col',
            selected: true,
          },
          {
            id: 2,
            name: 'Метки',
            baseName: 'mark',
            from: 'col',
            selected: false,
          },
          {
            id: 3,
            name: 'Запросы',
            baseName: 'request',
            from: 'col',
            selected: false,
          },
        ],
        listTabsRow: [
          {
            id: 1,
            name: 'Основные',
            baseName: 'basic',
            from: 'row',
            selected: true,
          },
          {
            id: 2,
            name: 'Метки',
            baseName: 'mark',
            from: 'row',
            selected: false,
          },
          {
            id: 3,
            name: 'Запросы',
            baseName: 'request',
            from: 'row',
            selected: false,
          },
        ],
        listTabsCell: [
          {
            id: 1,
            name: 'Метки',
            baseName: 'mark',
            from: 'cell',
            selected: true,
          },
          {
            id: 2,
            name: 'Запросы',
            baseName: 'request',
            from: 'cell',
            selected: false,
          },
        ],
        colType: [
          {
            value: -2,
            label: '-2 - основной столбец',
          },
          {
            value: -1,
            label: '-1 - столбец "№ п/п"',
          },
          {
            value: 0,
            label: '0 - обычный столбец (статический',
          },
          {
            value: 1,
            label: '1 - периодический столбец: годы',
          },
          {
            value: 2,
            label: '2 - периодический столбец: месяцы',
          },
          {
            value: 3,
            label: '3 - периодический столбец: недели',
          },
          {
            value: 4,
            label: '4 - периодический столбец: дни',
          },
        ],
        dataType: [
          {
            value: null,
            label: '0 - Не выбрано',
          },
          {
            value: 1,
            label: '1 - value_int',
          },
          {
            value: 2,
            label: '2 - value_dbl',
          },
          {
            value: 3,
            label: '3 - value_str',
          },
        ],
        tableId: null,
        tableItemsList: [
          {
            field: 'id',
            label: '1.id',
            type: 'number',
            disabled: true,
          },
          {
            field: 'title',
            label: '2.Название таблицы',
          },
          {
            field: 'sysName',
            label: '3.SYSNAME', // Валидация
          },
          {
            field: 'flgActive',
            label: '4.FLGACTIVE (1 по умолчанию)',
            checkbox: true,
          },
          {
            field: 'plReportId',
            label: '5.PLREPORTID (К какому отчету она пренадлежит)',
            type: 'number',
          },
          {
            field: 'plReportTabId',
            label: '6.PLREPORTTABID (На какой вкладке находится)',
            type: 'number',
            disabled: true,
          },
          {
            field: 'flgDynamicRows',
            label: '7.FLGDYNAMICROWS (Управление динамич строками по умол. 0, появляется +)',
            checkbox: true,
          },
          {
            field: 'flgPeriods',
            label: '8.FLGPERIODS (Переодическая ли таблица по умолч. 1 (МБ не используется, и будет удалена))',
            checkbox: true,
          },
          {
            field: 'flgRightSadvanced',
            label: '9.FLGRIGHTSADVANCED (Нужны дополнительные права на отображение таблицы, по умолч. 0 (не возвращается на фронт чисто в базу))',
            checkbox: true,
          },
          {
            field: 'sortIndex',
            label: '10.SORTINDEX',
            type: 'number',
          },
          {
            field: 'flgVisible',
            label: '11.FLGVISIBLE (Видимая ли она в презентации)',
            checkbox: true,
          },
          {
            field: 'parentTableId',
            label: '12.PARENTTABLEID (Ссылка на родительскую таблицу. Используется в динамических таблицах (flgdynamicrows=1))',
            type: 'number',
          },
          {
            field: 'plListId',
            label: '13.PLLISTID (Комбобоксы уточнить)',
            type: 'number',
          },
          {
            field: 'headerHeight',
            label: '14.HEADERHEIGHT Высота шапки по умол. (40px)',
          },
          {
            field: 'flgFilterData',
            label: '15.FLGFITLERDATA (По умол 0, уточнить)',
          },
        ],
        colItemsList: [
          {
            field: 'id',
            label: '1.id',
            type: 'number',
            disabled: true,
          },
          {
            field: 'title',
            label: '2.Название столбца',
          },
          {
            field: 'sysName',
            label: '3.SYSNAME (для удобочитаемости запросов PLANNING_QUERIES_)', // Валидация
          },
          {
            field: 'flgActive',
            label: '4.FLGACTIVE',
            checkbox: true,
          },
          {
            field: 'plTableId',
            label: '5.PLTABLEID (с какой таблице планирования присутствует столбец)',
            type: 'number',
            disabled: true,
          },
          {
            field: 'plListId',
            label: '6.PLLISTID (Шаблон списока, из элементов экземпляра которого могут браться значения для ячеек столбца)',
            type: 'number',
          },
          {
            field: 'description',
            label: '7.DESCRIPTION (пояснение для пользователей по назначению и правилам заполнения)',
          },
          {
            field: 'dataMask',
            label: '8.DATAMASK (i - в виде целого числа, и т.д.)',
            tooltip: true,
            tooltipText: staticData.TOOLTIP_ROW,
          },
          {
            field: 'dataType',
            label: '9.DATATYPE (Определяет, в какое поле будут записаны данные:1 - value_int 2 - value_dbl 3 - value_str)',
            select: true,
            selectType: 'dataType',
          },
          {
            field: 'sortIndex',
            label: '10.SORTINDEX (Порядок вывода столбца в отчете)',
            type: 'number',
          },
          {
            field: 'flgVisible',
            label: '11.FLGVISIBLE (Видимость столбца)',
            checkbox: true,
          },
          {
            field: 'colType',
            label: '12.COLTYPE (-2, -1, 0, 1, 2, 3, 4)',
            select: true,
            selectType: 'colType',
          },
          {
            field: 'plGroupId',
            label: '13.PLGROUPID (Если есть группировка столбцов)',
            type: 'number',
          },
          {
            field: 'width',
            label: '14.WIDTH',
          },
          {
            field: 'dataPeriodShift',
            label: '15.DATAPERIODSHIFT (определяет сдвиг (относительно текущей версии))',
            type: 'number',
          },
          {
            field: 'periodIcColsCount',
            label: '16.PERIODICCOLSCOUNT (количество периодических столбцов (если coltype 1..4))',
            type: 'number',
          },
          {
            field: 'plCalcGroupId',
            label: '17.PLCALCGROUPID (Принадлежность к группе для вычислений)',
            type: 'number',
          },
          {
            field: 'flgPresentVisible',
            label: '18.FLGPRESENTVISIBLE (Видимость столбца в презентации)',
            checkbox: true,
          },
        ],
        rowItemsList: [
          {
            field: 'id',
            label: '1.id',
            type: 'number',
            disabled: true,
          },
          {
            field: 'title',
            label: '2.Название строки',
          },
          {
            field: 'sysName',
            label: '3.SYSNAME', // Валидация
          },
          {
            field: 'flgActive',
            label: '4.FLGACTIVE',
            checkbox: true,
          },
          {
            field: 'description',
            label: '5.DESCRIPTION',
          },
          {
            field: 'plTableId',
            label: '6.PLTABLEID (К какой таблице относится Статья планирования)',
            type: 'number',
            disabled: true,
          },
          {
            field: 'plGroupId',
            label: '7.PLGROUPID',
            type: 'number',
          },
          {
            field: 'dataMask',
            label: '8.DATAMASK',
            tooltip: true,
            tooltipText: staticData.TOOLTIP_COL,
          },
          {
            field: 'dataType',
            label: '9.DATATYPE',
            select: true,
            selectType: 'dataType',
          },
          {
            field: 'sortIndex',
            label: '10.SORTINDEX (Порядок вывода строк в таблице)',
            type: 'number',
          },
          {
            field: 'flgVisible',
            label: '11.FLGVISIBLE (Видимость строки в таблице)',
            checkbox: true,
          },
          {
            field: 'flgNumerated',
            label: '12.FLGNUMERATED (Присутствует нумерация по порядку для этой строки)',
            checkbox: true,
          },
          {
            field: 'flgDynamic',
            label: '13.FLGDYNAMIC (Динамическая строка)',
            checkbox: true,
          },
          {
            field: 'height',
            label: '14.HEIGHT',
          },
          {
            field: 'dataPeriodShift',
            label: '15.DATAPERIODSHIFT (определяет сдвиг (относительно текущей версии) периода)',
            type: 'number',
          },
          {
            field: 'plCalcGroupId',
            label: '16.PLCALCGROUPID (Принадлежность к группе для вычислений)',
            type: 'number',
          },
          {
            field: 'flgPresentVisible',
            label: '17.FLGPRESENTVISIBLE (Видимость строки в презентации)',
            checkbox: true,
          },
          {
            field: 'plGroupParentRowId',
            label: '18.PLGROUPPARENTROWID (строка-родитель для группы сворачивания)',
            type: 'number',
          },
          {
            field: 'flgColLapsed',
            label: '20.FLGCOLLAPSED (каком виде отображать группу сворачивания при первоначальной загрузке)',
            checkbox: true,
          },
        ],
        modeEditCol: false,
        modeEditRow: false,
        cellActive: null,
        cellEditState: {},
        queriesByTable: null,
      }
    },
    mounted () {
      this.initTable()
      this.getStylesTable(this.tableId)
      this.getMarksTable(this.tableId)
    },
    methods: {
      showTableSettingsPanel () {
        this.curStepOnTable = 'table'
        this.resetSelectedTableElementsId('all')
        this.tableTabsInfo.marksCurTableCopy = JSON.parse(JSON.stringify(this.tableTabsInfo.marksCurTable))
        this.activeBorderReset()
      },
      showColSettingsPanel () {
        this.curStepOnTable = 'col'
        this.modeEditCol = false
        this.activeTabTable(this.listTabsCol, this.listTabsCol[0])
        this.$store.commit('setBuilderTemplatePanelSettingsOpen', true)
        this.activeBorderReset()
        this.resetDefaultCol()
      },
      showRowSettingsPanel () {
        this.curStepOnTable = 'row'
        this.modeEditRow = false
        this.activeTabTable(this.listTabsRow, this.listTabsRow[0])
        this.$store.commit('setBuilderTemplatePanelSettingsOpen', true)
        this.activeBorderReset()
        this.resetDefaultRow()
      },
      showCellSettingsPanel () {
        this.curStepOnTable = 'cell'
        this.activeBorderReset()
      },
      choiceRowInTableSettings (style) {
        this.activeBorderReset()
        if (style.plrowid && style.plcolid) {
          if (this.cellActive || (this.cellActive.colId === style.plcolid && this.cellActive.rowId === style.plrowid)) {
            return // выходим для того что бы по клику на строчку с уже выбранной ячейкой обводка не сбивалась
          }
          this.cellActive = {
            colId: style.plcolid,
            rowId: style.plrowid,
          }
        }
        if (style.plrowid && !style.plcolid) {
          this.choiceRowActiveFromSettings = style.plrowid
        }
        if (!style.plrowid && style.plcolid) {
          this.choiceColActiveFromSettings = style.plcolid
        }
      },
      togglePanel () {
        this.$store.commit('setBuilderTemplatePanelSettingsOpen', !this.builderTemplatePanelSettingsOpen)
      },
      activeTabTable (masElem, elem) {
        masElem.forEach((item) => {
          this.$set(item, 'selected', false)
        })
        this.$set(elem, 'selected', true)

        if (elem.from === 'table') {
          for (const key in this.activeTab.table) {
            this.$set(this.activeTab.table, key, false)
          }
          this.$set(this.activeTab.table, elem.baseName, true)
        }

        if (elem.from === 'col') {
          for (const key in this.activeTab.col) {
            this.$set(this.activeTab.col, key, false)
          }
          this.$set(this.activeTab.col, elem.baseName, true)
        }

        if (elem.from === 'row') {
          for (const key in this.activeTab.row) {
            this.$set(this.activeTab.row, key, false)
          }
          this.$set(this.activeTab.row, elem.baseName, true)
        }

        if (elem.from === 'cell') {
          for (const key in this.activeTab.cell) {
            this.$set(this.activeTab.cell, key, false)
          }
          this.$set(this.activeTab.cell, elem.baseName, true)
        }
      },
      cancelMoveChanges () {
        this.tableInfoCopy = JSON.parse(JSON.stringify(this.tableInfo))
        this.dragEventsCancel()
        this.activeBorderReset()
      },
      addCol (col) {
        const objCol = {
          title: col.title,
          sysname: col.sysName,
          flgactive: col.flgActive,
          pltableid: col.plTableId,
          pllistid: col.plListId,
          description: col.plListId,
          datamask: col.dataMask,
          datatype: col.dataType,
          dataperiodshift: col.dataPeriodShift,
          sortindex: col.sortIndex,
          flgvisible: col.flgVisible,
          coltype: col.colType,
          periodiccolscount: col.periodIcColsCount,
          plgroupid: col.plGroupId,
          width: col.width,
          plcalcgroupid: col.plCalcGroupId,
          flgpresentvisible: col.flgPresentVisible,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('createNewReportTableCol', objCol).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.closeAddDialogs()
          this.getTableTemplateRequest()
        }).catch((error) => {
          console.error(error)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      addRow (row) {
        const objRow = {
          title: row.title,
          sysname: row.sysName,
          flgactive: row.flgActive,
          description: row.description,
          pltableid: row.plTableId,
          plgroupid: row.plGroupId,
          datamask: row.dataMask,
          datatype: row.dataType,
          dataperiodshift: row.dataPeriodShift,
          sortindex: row.sortIndex,
          flgvisible: row.flgVisible,
          flgnumerated: row.flgNumerated,
          flgdynamic: row.flgDynamic,
          height: row.height,
          plcalcgroupid: row.plCalcGroupId,
          flgpresentvisible: row.flgPresentVisible,
          plgroupparentrowid: row.plGroupParentRowId,
          flgcollapsed: row.flgColLapsed,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('createNewReportTableRow', objRow).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.closeAddDialogs()
          this.getTableTemplateRequest()
        }).catch((error) => {
          console.error(error)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      editTable (table) {
        // Редактировать таблицу
        const objUpdateTable = {
          id: table.id,
          title: table.title,
          sysname: table.sysName,
          flgactive: table.flgActive,
          plreportid: table.plReportId,
          plreporttabid: table.plReportTabId,
          flgdynamicrows: table.flgDynamicRows,
          flgperiods: table.flgPeriods,
          flgrightsadvanced: table.flgRightSadvanced,
          sortindex: table.sortIndex,
          flgvisible: table.flgVisible,
          parenttableid: table.parentTableId,
          pllistid: table.plListId,
          headerheight: table.headerHeight,
          flgfilterdata: table.flgFilterData,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('updatePlanningTableList', objUpdateTable).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.getTableTemplateRequest()
        }).catch((e) => {
          console.error('Error:', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      editCol (col) {
        // Редактировать Колонку
        const objUpdateCol = {
          id: col.id,
          title: col.title,
          sysname: col.sysName,
          flgactive: col.flgActive,
          pltableid: col.plTableId,
          pllistid: col.plListId,
          description: col.description,
          datamask: col.dataMask,
          datatype: col.dataType,
          dataperiodshift: col.dataPeriodShift,
          sortindex: col.sortIndex,
          flgvisible: col.flgVisible,
          coltype: col.colType,
          periodiccolscount: col.periodIcColsCount,
          plgroupid: col.plGroupId,
          width: col.width,
          plcalcgroupid: col.plCalcGroupId,
          flgpresentvisible: col.flgPresentVisible,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('updateReportTableCol', objUpdateCol).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.getTableTemplateRequest()
        }).catch((e) => {
          console.error('Error:', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      editRow (row) {
        // Редактировать Колонку
        const objUpdateRow = {
          id: row.id,
          title: row.title,
          sysname: row.sysName,
          flgactive: row.flgActive,
          description: row.description,
          pltableid: row.plTableId,
          plgroupid: row.plGroupId,
          datamask: row.dataMask,
          datatype: row.dataType,
          dataperiodshift: row.dataPeriodShift,
          sortindex: row.sortIndex,
          flgvisible: row.flgVisible,
          flgnumerated: row.flgNumerated,
          flgdynamic: row.flgDynamic,
          height: row.height,
          plcalcgroupid: row.plCalcGroupId,
          flgpresentvisible: row.flgPresentVisible,
          plgroupparentrowid: row.plGroupParentRowId,
          flgcollapsed: row.flgColLapsed,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('updateReportTableRow', objUpdateRow).then(() => {
          this.$store.dispatch('setDataLoading', false)
          this.getTableTemplateRequest()
        }).catch((e) => {
          console.error('Error:', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      delElTemplate (id, type) {
        this.$refs.dlgDel.open({
          title: 'Удалить объект?',
          acceptText: 'Продолжить',
          cancelText: 'Отмена',
          dialogMaxWidth: 440,
        }).then(() => {
          if (type === 'col') {
            const payload = {
              id: id,
            }
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('delReportTableCol', payload).then(() => {
              this.getTableTemplateRequest()
            }).catch((e) => {
              console.error('Error: ', e)
              this.$store.dispatch('setDataLoading', false)
            })
          }
          if (type === 'row') {
            const payload = {
              id: id,
            }
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('delReportTableRow', payload).then(() => {
              this.getTableTemplateRequest()
            }).catch((e) => {
              console.error('Error: ', e)
              this.$store.dispatch('setDataLoading', false)
            })
          }
        })
      },
      closeAddDialogs () {
        this.showAddCategory = false
        this.showAddTemplate = false
        this.showAddTab = false
        this.showAddTable = false
      },
      settingsRowOpen (row, index) {
        this.showRowSettingsPanel()
        this.resetSelectedTableElementsId('row')
        this.filterMarkInTableList('row', row.id, null)
        this.modeEditRow = true
        this.choiceColActive = null
        this.choiceRowActive = index
        this.changedRow.id = row.id
        this.changedRow.title = row.title
        this.changedRow.sysName = row.sysname
        this.changedRow.flgActive = row.flgactive
        this.changedRow.description = row.description
        // this.changedRow.plTableId = row.pltableid
        this.changedRow.plGroupId = row.plgroupid
        this.changedRow.dataMask = row.datamask
        this.changedRow.dataType = row.datatype
        this.changedRow.dataPeriodShift = row.dataperiodshift
        this.changedRow.sortIndex = row.sortindex
        this.changedRow.flgVisible = row.flgvisible
        this.changedRow.flgNumerated = row.flgnumerated
        this.changedRow.flgDynamic = row.flgdynamic
        this.changedRow.height = row.height
        this.changedRow.plCalcGroupId = row.plcalcgroupid
        this.changedRow.flgPresentVisible = row.flgpresentvisible
        this.changedRow.plGroupParentRowId = row.plgroupparentrowid
        this.changedRow.flgColLapsed = row.flgcollapsed
      },
      settingsColOpen (col, index) {
        this.showColSettingsPanel()
        this.resetSelectedTableElementsId('col')
        this.filterMarkInTableList('col', null, col.id)
        this.curStepOnTable = 'col'
        this.modeEditCol = true
        this.choiceRowActive = null
        this.choiceColActive = index
        this.changedCol.id = col.id
        this.changedCol.title = col.title
        this.changedCol.sysName = col.sysname
        this.changedCol.flgActive = col.flgactive
        // this.changedCol.plTableId = col.pltableid
        this.changedCol.plListId = col.pllistid
        this.changedCol.description = col.description
        this.changedCol.dataMask = col.datamask
        this.changedCol.dataType = col.datatype
        this.changedCol.dataPeriodShift = col.dataperiodshift
        this.changedCol.sortIndex = col.sortindex
        this.changedCol.flgVisible = col.flgvisible
        this.changedCol.colType = col.coltype
        this.changedCol.periodIcColsCount = col.periodiccolscount
        this.changedCol.plGroupId = col.groupid // Приходит groupid вместо plgroupid
        this.changedCol.width = col.width
        this.changedCol.plCalcGroupId = col.plcalcgroupid
        this.changedCol.flgPresentVisible = col.flgpresentvisible
      },
      settingsCellOpen (col, row) {
        this.resetSelectedTableElementsId('cell')
        this.filterMarkInTableList('cell', row.id, col.id)
        this.showCellSettingsPanel()
        // if (!this.cellActive || (this.cellActive.colId === col.id && this.cellActive.rowId === row.id)) {
        this.cellActive = {
          colId: col.id,
          rowId: row.id,
        }
      },
      filterMarkInTableList (type, rowId, colId) {
        if (type === 'cell') {
          this.tableTabsInfo.marksCurTableCopy = this.tableTabsInfo.marksCurTable.filter(item => item.plrowid === rowId && item.plcolid === colId)
        }
        if (type === 'col') {
          this.tableTabsInfo.marksCurTableCopy = this.tableTabsInfo.marksCurTable.filter(item => item.plrowid === null && item.plcolid === colId)
        }
        if (type === 'row') {
          this.tableTabsInfo.marksCurTableCopy = this.tableTabsInfo.marksCurTable.filter(item => item.plrowid === rowId && item.plcolid === null)
        }
      },
      activeBorderReset () {
        this.hoverCol = null
        this.choiceColActive = null
        this.hoverRow = null
        this.choiceRowActive = null
        this.choiceRowActiveFromSettings = null
        this.choiceColActiveFromSettings = null
        this.cellActive = null
      },
      resetDefaultCol () {
        // сбрасываем на дефолтные настройки для добавления столбца
        this.changedCol.id = null
        this.changedCol.title = null
        this.changedCol.sysName = null
        this.changedCol.flgActive = true
        this.changedCol.plListId = null
        this.changedCol.description = null
        this.changedCol.dataMask = null
        this.changedCol.dataType = null
        this.changedCol.dataPeriodShift = null
        this.changedCol.sortIndex = null
        this.changedCol.flgVisible = true
        this.changedCol.colType = 0
        this.changedCol.periodIcColsCount = null
        this.changedCol.plGroupId = null
        this.changedCol.width = null
        this.changedCol.plCalcGroupId = null
        this.changedCol.flgPresentVisible = true
      },
      resetDefaultRow () {
        // сбрасываем на дефолтные настройки для добавления строки
        this.changedRow.id = null
        this.changedRow.title = null
        this.changedRow.sysName = null
        this.changedRow.flgActive = true
        this.changedRow.description = null
        this.changedRow.plGroupId = null
        this.changedRow.dataMask = null
        this.changedRow.dataType = null
        this.changedRow.dataPeriodShift = null
        this.changedRow.sortIndex = null
        this.changedRow.flgVisible = true
        this.changedRow.flgNumerated = false
        this.changedRow.flgDynamic = false
        this.changedRow.height = '40px'
        this.changedRow.plCalcGroupId = null
        this.changedRow.flgPresentVisible = true
        this.changedRow.plGroupParentRowId = null
        this.changedRow.flgColLapsed = null
      },
      changeColPosition (direction) {
        const arr = this.tableInfoCopy.col
        const index = this.choiceColActive
        this.changeDragEventCol = true
        if (direction === 'left') {
          if (index === 0) {
            return // Если индекс равен 1 и направление 'left', то нет элемента для обмена
          }
          [arr[index], arr[index - 1]] = [arr[index - 1], arr[index]] // Меняем местами текущий элемент и предыдущий элемент (деструктуризация)
          this.choiceColActive -= 1
        } else if (direction === 'right') {
          if (index === arr.length - 1) {
            return // Если индекс равен последнему элементу и направление 'right', то нет элемента для обмена
          }
          [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]] // Меняем местами текущий элемент и следующий элемент (деструктуризация)
          this.choiceColActive += 1
        }
      },
      changeRowPosition (direction) {
        const arr = this.tableInfoCopy.row
        const index = this.choiceRowActive
        this.changeDragEventRow = true
        if (direction === 'top') {
          if (index === 0) {
            return // Если индекс равен 0 и направление 'top', то нет элемента для обмена
          }
          [arr[index], arr[index - 1]] = [arr[index - 1], arr[index]] // Меняем местами текущий элемент и предыдущий элемент (деструктуризация)
          this.choiceRowActive -= 1
        } else if (direction === 'bottom') {
          if (index === arr.length - 1) {
            return // Если индекс равен последнему элементу и направление 'bottom', то нет элемента для обмена
          }
          [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]] // Меняем местами текущий элемент и следующий элемент (деструктуризация)
          this.choiceRowActive += 1
        }
      },
      validationSysName (event, el) {
        if (!event) {
          return
        }
        const regex = /^[a-zA-Z0-9_]+$/
        if (!regex.test(event)) {
          this.$nextTick(() => {
            el.sysName = event.slice(0, -1)
          })
          return
        }
        el.sysName = el.sysName.toUpperCase()
      },
      updateSettingsTableInTable () {
        this.curStepOnTable = 'table'
        this.getTableTemplateRequest()
        this.getStylesTable(this.tableId)
        this.getMarksTable(this.tableId)
      },
      delElementSettings () {
        this.getTableTemplateRequest()
      },
      changeStylePresentationMode (presentationModeActive) {
        this.stylePresentationModeActive = presentationModeActive
      },
      getStylesTable (tableId) {
        this.$store.dispatch('setDataLoading', true)
        const deleteElHeader = ['id', 'pltableid', 'style', 'description']
        setTimeout(() => {
          this.$store.dispatch('getStylesTableInfo', tableId).then((res) => {
            // если в данной таблице нет стилей, добавляем пустой элемент, что бы на основе его можно было создать стиль
            if (!res.length) {
              res.push({
                plrowid: null,
                plcolid: null,
                pltableid: this.tableId,
                stylesid: null,
                priority: null,
                can_edit: null,
                flgheader: 0,
                flg_presentation: 0,
              })
              this.tableTabsInfo.styleCurTableHeader = Object.keys(res[0])
              this.tableTabsInfo.styleCurTableHeader = this.tableTabsInfo.styleCurTableHeader.filter(item => !deleteElHeader.includes(item))
              this.tableTabsInfo.styleCurTable = []
              return
            }
            this.tableTabsInfo.styleCurTable = res.sort((a, b) => a.priority - b.priority)
            this.tableTabsInfo.styleCurTableHeader = Object.keys(this.tableTabsInfo.styleCurTable[0])
            this.tableTabsInfo.styleCurTableHeader = this.tableTabsInfo.styleCurTableHeader.filter(item => !deleteElHeader.includes(item))
          }).catch((e) => {
            console.error('Error: ', e)
            this.$store.dispatch('setDataLoading', false)
          })
        }, 0)
      },
      getMarksTable (tableId) {
        this.$store.dispatch('setDataLoading', true)
        const deleteElHeader = ['id', 'pltableid', 'style', 'description', 'categoryid']
        setTimeout(() => {
          this.$store.dispatch('getMarkTable', tableId).then((res) => {
            this.tableTabsInfo.marksCurTable = res.sort((a, b) => a.priority - b.priority)
            this.tableTabsInfo.marksCurTableCopy = res.sort((a, b) => a.priority - b.priority)
            // если в данной таблице нет меток, добавляем пустой элемент, что бы на основе его можно было создать метку
            if (!res.length) {
              res.push({
                categoryid: null,
                pltableid: this.tableId,
                plrowid: null,
                plcolid: null,
                value_int: null,
                value_str: null,
                title: null,
              })
              this.tableTabsInfo.marksCurTableCopyHeader = Object.keys(res[0])
              this.tableTabsInfo.marksCurTableCopyHeader = this.tableTabsInfo.marksCurTableCopyHeader.filter(item => !deleteElHeader.includes(item))
              this.tableTabsInfo.marksCurTableCopy = []
              return
            }
            this.tableTabsInfo.marksCurTableCopyHeader = Object.keys(this.tableTabsInfo.marksCurTableCopy[0])
            this.tableTabsInfo.marksCurTableCopyHeader = this.tableTabsInfo.marksCurTableCopyHeader.filter(item => !deleteElHeader.includes(item))
            this.$store.dispatch('setDataLoading', false)
          }).catch((e) => {
            console.error('Error: ', e)
            this.$store.dispatch('setDataLoading', false)
          })
        }, 0)
      },
      selectTable (table) {
        this.planningTables = {
          id: table.id,
          title: table.title,
          sysName: table.sysname,
          flgActive: table.flgactive,
          plReportId: table.plreportid,
          plReportTabId: table.plreporttabid,
          flgDynamicRows: table.flgdynamicrows,
          flgPeriods: table.flgperiods,
          flgRightSadvanced: table.flgrightsadvanced,
          sortIndex: table.sortindex,
          flgVisible: table.flgvisible,
          parentTableId: table.parenttableid,
          plListId: table.pllistid,
          headerHeight: table.headerheight,
          flgFilterData: table.flgfitlerdata, // баг в названии в базе
        }
        this.getTableTemplateRequest()
        this.getStylesTable(table.id)
        this.getMarksTable(table.id)
        this.changedCol.plTableId = table.id
        this.changedRow.plTableId = table.id
      },
      closeSettingsInList () {
        this.chosenCategoryInlist = null
        this.chosenTemplateInlist = null
        this.chosenTabInlist = null
      },
      getTableTemplateRequest () {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getTableTemplate', this.planningTables.id).then((res) => {
          this.tableInfoCopy = JSON.parse(JSON.stringify(this.tableInfo))
          this.tableInfoCopy = res
          this.tableInfoCopy.row = this.tableInfoCopy.row.sort((a, b) => {
            return parseInt(a.sortindex) - parseInt(b.sortindex)
          })
          this.tableInfoCopy.col = this.tableInfoCopy.col.sort((a, b) => {
            return parseInt(a.sortindex) - parseInt(b.sortindex)
          })
          this.tableInfoCopy = JSON.parse(JSON.stringify(this.tableInfoCopy))
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      async changeSortIndex (type) {
        this.$store.dispatch('setDataLoading', true)
        if (type === 'row/col') {
          if (this.changeDragEventRow) { // если перестановка в строках
            const maxSortIndex = Math.max(...this.tableInfoCopy.row.map(item => parseInt(item.sortindex)))
            let newSortIndexStr = ''
            await this.tableInfoCopy.row.forEach((item, index) => {
              item.sortindex = index + maxSortIndex + 1
              newSortIndexStr += `${item.id}${index !== this.tableInfoCopy.row.length - 1 ? `,${index + maxSortIndex + 1}^${index},` : `,${index + maxSortIndex + 1}^${index}`}` // Делает строку типа - 64,46^0,65,47^1,63,48^2
            })
            this.closeSettingsInList()
            const payload = { data: newSortIndexStr }
            this.$store.dispatch('updateReportTableRowSortindex', payload).then(() => {
              this.$store.dispatch('setDataLoading', false)
              this.getTableTemplateRequest()
            }).catch((e) => {
              console.error('Error: ', e)
              this.$store.dispatch('setDataLoading', false)
            })
          }
          if (this.changeDragEventCol) { // если перестановка в колонках
            const maxSortIndex = Math.max(...this.tableInfoCopy.col.map(item => parseInt(item.sortindex)))
            let newSortIndexStr = ''
            await this.tableInfoCopy.col.forEach((item, index) => {
              item.sortindex = index + maxSortIndex + 1
              newSortIndexStr += `${item.id}${index !== this.tableInfoCopy.col.length - 1 ? `,${index + maxSortIndex + 1}^${index},` : `,${index + maxSortIndex + 1}^${index}`}` // Делает строку типа - 64,46^0,65,47^1,63,48^2
            })
            this.closeSettingsInList()
            const payload = { data: newSortIndexStr }
            this.$store.dispatch('updateReportTableColSortindex', payload).then(() => {
              this.$store.dispatch('setDataLoading', false)
              this.getTableTemplateRequest()
            }).catch((e) => {
              console.error('Error: ', e)
              this.$store.dispatch('setDataLoading', false)
            })
          }
          this.dragEventsCancel()
        }
      },
      dragEventsCancel () {
        this.changeDragEvent = false
        this.changeDragEventCol = false
        this.changeDragEventRow = false
      },
      initTable () {
        this.tableInfoCopy = JSON.parse(JSON.stringify(this.tableInfo))
        this.tableId = this.tableInfoCopy.info.id
        this.selectTable(this.planningListTables)
      },
      resetSelectedTableElementsId (chosenType) {
        if (chosenType === 'col') {
          this.changedRow.id = null
          this.cellActive = null
        }
        if (chosenType === 'row') {
          this.changedCol.id = null
          this.cellActive = null
        }
        if (chosenType === 'cell') {
          this.changedRow.id = null
          this.changedCol.id = null
        }
        if (chosenType === 'all') {
          this.cellActive = null
          this.changedRow.id = null
          this.changedCol.id = null
        }
      },
      getQueriesTable (tableId) {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('planningTableQueriesInfo', tableId).then((res) => {
          this.queriesByTable = res.sort((a, b) => {
            return parseInt(a.calcindex) - parseInt(b.calcindex)
          })
          this.queriesByTable.forEach((item) => {
            if (item.plcolid) {
              item.colName = this.getTitleOrDescriptionById(item.plcolid, this.tableInfoCopy.col)
            }
            if (item.plrowid) {
              item.rowName = this.getTitleOrDescriptionById(item.plrowid, this.tableInfoCopy.row)
            }
          })
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      getTitleOrDescriptionById (id, data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].id === id) {
            return data[i].title || data[i].description
          }
        }
        return null
      },
    },
    computed: {
      ...mapGetters(['tableStyles', 'builderTemplatePanelSettingsOpen']),
      cellStyle () {
        return (cellData, colId, rowId, isHeader) => {
          // расчитываем сстиль каждой ячейки
          let curCellStyle = ''
          if (!cellData) return
          cellData.forEach((item) => {
            if (!this.stylePresentationModeActive && !item.flg_presentation && !item.can_edit) {
              if (isHeader) {
                if (!item.plrowid && !item.plcolid && item.flgheader) {
                  curCellStyle += item.style
                }
              } else {
                if (!item.plrowid && !item.plcolid && !item.flgheader) {
                  curCellStyle += item.style
                }
                if (colId === item.plcolid || rowId === item.plrowid) {
                  curCellStyle += item.style
                }
              }
            } else if (this.stylePresentationModeActive && !item.can_edit) {
              if (isHeader) {
                if (!item.plrowid && !item.plcolid && item.flgheader) {
                  curCellStyle += item.style
                }
              } else {
                if (!item.plrowid && !item.plcolid && !item.flgheader) {
                  curCellStyle += item.style
                }
                if (colId === item.plcolid || rowId === item.plrowid) {
                  curCellStyle += item.style
                }
              }
            } else if (item.can_edit && this.cellEditState[`${colId}+${rowId}`]) {
              curCellStyle += item.style
            }
          })

          return curCellStyle
        }
      },
      selectedTableElements () {
        const obj = {
          colId: this.changedCol.id,
          rowId: this.changedRow.id,
          cell: this.cellActive,
        }
        return obj
      },
    },
    watch: {
      tableInfo: {
        handler () {
          this.initTable()
        },
        deep: true,
      },
      tableId: {
        handler () {
          this.getQueriesTable(this.tableId)
        },
        deep: true,
      },
    },
  }
</script>

<style scoped lang="scss">
.settings-panel__wrapper {
  position: relative;
  height: fit-content;
  .settings-panel {
    width: 550px;
    margin: 0;
    height: auto;
    max-height: 85vh;
    overflow-y: scroll;
  }
  .arrow-settings-panel {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translate(0, -50%) rotate(180deg);
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23231f20;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EWondicon - UI (Free)%3C/title%3E%3Cpath class='cls-1' d='M117.86,140.5a5,5,0,0,1-3.53-1.46L78.6,103.32a5,5,0,0,1,0-7.07l35.73-35.73a5,5,0,1,1,7.07,7.07L89.21,99.78,121.4,132a5,5,0,0,1-3.54,8.53Z'/%3E%3Cpath class='cls-1' d='M154.29,175.69H46a21.57,21.57,0,0,1-21.54-21.54V45.85A21.57,21.57,0,0,1,46,24.31H154.29a21.57,21.57,0,0,1,21.54,21.54v108.3A21.57,21.57,0,0,1,154.29,175.69ZM46,34.31A11.55,11.55,0,0,0,34.46,45.85v108.3A11.55,11.55,0,0,0,46,165.69H154.29a11.55,11.55,0,0,0,11.54-11.54V45.85a11.55,11.55,0,0,0-11.54-11.54Z'/%3E%3C/svg%3E");
  }
}
.arrow-rotate {
  height: auto;
  .arrow-settings-panel {
    transform: translate(0, -50%) rotate(0);
  }
  .settings-panel {
    width: auto;
    height: 100%;
    max-height: none;
  }
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 50px;
}

th, td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

th {
  position: relative;
  .header-settings-elem {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translate(0, -50%);
    cursor: pointer;
  }
}
.td-body-table {
  position: relative;
  .cell-settings-elem {
    position: absolute;
    top: 0;
    right: 5px;
    display: none;
    cursor: pointer;
    .cell-checkbox {
      position: absolute;
      top: -2px;
      left: -25px;
      margin: 0;
    }
  }
  &:hover {
    border: 2px solid #00b2f9;
    .cell-settings-elem {
      display: block;
    }
  }
}
.table-wrapper {
  position: relative;
  .arrow-col-block {
    width: auto;
    position: absolute;
    top: -44px;
    right: 0;
  }
  .arrow-row-block {
    width: auto;
    position: absolute;
    top: 13px;
    left: -62px;
    transform: rotate(90deg);
  }
}
.row-wrapper {
  justify-content: center;
  div {
    padding-bottom: 0;
    div {
      padding-bottom: 0;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.background-active {
  background-color: rgba(0, 0, 0, 0.04);
}

/*tr:hover {
  background-color: #f5f5f5;
}*/
.hover-border {
  // границы для боков активных колонок
  border: 2px solid #00b2f9;
  //td {
  //  border: 2px solid red !important;
  //  z-index: 9999;
  //}
}
.hover-border-col {
  // границы для боков активных колонок
  border: 2px solid #00b2f9;
  border-top: transparent;
  border-bottom: transparent;
}
tr {
  &:last-child {
    .hover-border-col {
      // граница для низа активной колонки
      border-bottom: 2px solid #00b2f9;
    }
  }
}
.hover-border-header {
  // граница для headera таблицы
  border: 2px solid #00b2f9;
  border-bottom: transparent;
  //th {
  //  border: 2px solid red !important;
  //  z-index: 9999;
  //}
}
thead {
  tr:first-child:hover {
    background-color: initial;
  }
}
.settings {
  width: 20px;
  cursor: pointer;
}
</style>
