<template>
  <v-col class="wrapper py-6 my-2">
    <v-list
      v-for="(item, count) in requestsCopy"
      :key="item.querieid"
      class="card-text px-6 py-2"
      :class="{'disabled': !item.flgactive}"
    >
      <div class="header-request">
        <span class="header-line my-0">{{count + 1}})&nbsp;
          plcolid - <span>{{item.plcolid ? item.plcolid : 'null' }} <span v-if="item.colName"> ({{item.colName}})</span> </span>
          plrowid - <span>{{item.plrowid ? item.plrowid : 'null'}}<span v-if="item.rowName"> ({{item.rowName}})</span> </span>
        </span>
        <p class="my-0">flgouterdata - {{ item.flgouterdata }} | calcindex - {{ item.calcindex }}</p>
        <v-btn
          class="open-big-button"
          dark
          icon
          :color="'blue-grey lighten-3'"
          @click="choiceBigModalOpen(item)"
        >
          <v-icon>
            mdi-arrow-top-right
          </v-icon>
        </v-btn>
      </div>
      <v-col
        v-if="item.description"
        class="description-main px-0 py-0"
      >
        <span>Описание: </span>
        <span>{{item.description}}</span>
      </v-col>
      <hr class="my-2">
    </v-list>
    <v-dialog
      v-if="choiceBigRequestModalOpen"
      v-model="choiceBigRequestModalOpen"
      width="1260"
    >
      <v-card class="request-big px-10 py-10">
        <div class="header-request">
          <p class="my-0 font-weight-bold">
            plcolid - <span>{{choiceBigRequest.plcolid ? choiceBigRequest.plcolid : 'null' }} <span v-if="choiceBigRequest.colName"> ({{choiceBigRequest.colName}})</span> </span>
            plrowid - <span>{{choiceBigRequest.plrowid ? choiceBigRequest.plrowid : 'null'}}<span v-if="choiceBigRequest.rowName"> ({{choiceBigRequest.rowName}})</span> </span>
            | <span class="my-0">flgouterdata - {{ choiceBigRequest.flgouterdata }} | calcindex - {{ choiceBigRequest.calcindex }}</span>
          </p>
        </div>
        <hr class="my-2">
        <prism
          inline
          language="sql"
        >
          {{choiceBigRequest.dataquery}}
        </prism>
        <hr class="my-2">
        <v-col
          v-if="choiceBigRequest.description"
          class="px-0"
        >
          <span>Описание:</span>
          <p>{{choiceBigRequest.description}}</p>
        </v-col>
        <v-btn
          class="d-flex"
          style="background: #999; margin: 0 auto"
          color="white"
          text
          @click="choiceBigRequestModalOpen = !choiceBigRequestModalOpen"
        >
          закрыть
        </v-btn>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
  import 'prismjs'
  import 'prismjs/themes/prism.css'
  import 'prismjs/components/prism-sql'
  import Prism from 'vue-prism-component'
  export default {
    name: 'builderTemplateRequest',
    components: {
      Prism,
    },
    props: {
      requests: {
        type: Array,
        default: () => [],
        required: true,
      },
      selectedTableElements: {
        type: Object,
        default: () => {},
      },
      listTabsTables: {
        type: Array,
        default: () => [],
      },
      queryTypeDynamic: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        requestsCopy: null,
        choiceBigRequest: null,
        choiceBigRequestModalOpen: false,
      }
    },
    computed: {
      typeRequest () {
        const typeRequest = {
          requestTable: !(!!this.selectedTableElements.colId || !!this.selectedTableElements.rowId || !!this.selectedTableElements.cell),
          requestCol: !!this.selectedTableElements.colId,
          requestRow: !!this.selectedTableElements.rowId,
          requestCell: !!this.selectedTableElements.cell,
        }
        return typeRequest
      },
    },
    watch: {
      requests: {
        handler () {
          this.requestsCopy = JSON.parse(JSON.stringify(this.requests))
          this.filterRequest()
        },
        immediate: true,
      },
      listTabsTables: {
        handler () {
          this.filterRequest()
        },
        deep: true,
      },
      selectedTableElements: {
        handler () {
          this.filterRequest()
        },
        deep: true,
      },
    },
    methods: {
      filterRequest () {
        if (!this.requestsCopy) return
        this.requestsCopy = this.requests.filter(item => (item.querytype === '1' && !this.queryTypeDynamic) ||
          (item.querytype === '2' && this.queryTypeDynamic)
        )
        if (this.typeRequest.requestCol) {
          this.requestsCopy = this.requests.filter(item => item.plrowid === null && item.plcolid === this.selectedTableElements.colId)
        }
        if (this.typeRequest.requestRow) {
          this.requestsCopy = this.requests.filter(item => item.plrowid === this.selectedTableElements.rowId && item.plcolid === null)
        }
        if (this.typeRequest.requestCell) {
          this.requestsCopy = this.requests.filter(item => item.plrowid === this.selectedTableElements.cell.rowId && item.plcolid === this.selectedTableElements.cell.colId)
        }
      },
      choiceBigModalOpen (modalDate) {
        this.choiceBigRequest = modalDate
        this.choiceBigRequestModalOpen = true
      },
    },
  }
</script>

<style scoped lang="scss">
.wrapper {
  background: #f5f2f0;
}
.header-request {
  position: relative;
  display: flex;
  flex-direction: column;
  .open-big-button {
    position: absolute;
    top: -7px;
    right: 0;
  }
}
.card-text {
  font-size: 14px;
  background: #f5f2f0;
  code {
    white-space: break-spaces;
    background: transparent;
  }
}
.request-big {
  code {
    white-space: break-spaces;
    background: transparent;
  }
  .language-sql {
    white-space: break-spaces;
  }
}
.description-main {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.header-line {
  width: calc(100% - 25px);
}
.disabled {
  opacity: .5;
}
</style>
