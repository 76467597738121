<template>
  <div class="table-wrapper">
    <div class="table-header py-5">
      <div
        class="d-flex"
        :class="type === 'style' ? 'justify-space-between' : 'justify-end'"
      >
        <div
          v-if="type === 'style'"
          class="px-0 py-0"
        >
          <v-switch
            class="my-0"
            v-model="stylePresentationMode"
            :label="`Стили презентации`"
            @change="changeStylePresentationMode"
          />
        </div>
        <div class="px-0 py-0">
          <div v-if="showFilter">
            <v-autocomplete
              v-model="filterChoice"
              class="pt-0 mb-0"
              hide-details
              :items="allCols"
              item-text="title"
              item-value="id"
              multiple
              label="Фильтровать title"
              :search-input.sync="searchText"
            >
              <template #prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggleCols"
                >
                  <v-list-item-action>
                    <v-icon :color="filterChoice && filterChoice.length > 0 ? 'indigo darken-4' : ''">
                      {{ iconCols }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!searchFilterActive">Выбрать все</span>
                      <span v-else>Выбрать найденные</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip
                  v-if="index === 0"
                  x-small
                >
                  <span>{{ item }}{{ item.length > 6 ? '...' : '' }}</span>
                </v-chip>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ filterChoice.length - 1 }} других)
                </span>
              </template>
            </v-autocomplete>
          </div>
          <v-btn
            v-if="type === 'mark'"
            icon
            dark
            :color="'blue-grey lighten-3'"
            @click="showFilter= !showFilter"
          >
            <v-icon large>
              mdi-filter-outline
            </v-icon>
          </v-btn>
          <v-btn
            icon
            dark
            :color="'blue-grey lighten-3'"
            @click="addNewRow"
          >
            <v-icon large>
              mdi-plus-box-outline
            </v-icon>
          </v-btn>
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :dark="tableChange"
                :disabled="!tableChange"
                :color="'blue-grey lighten-3'"
                icon
                text
                v-on="on"
                @click="reverseChanges"
              >
                <v-icon
                  :style="!tableChange?'color: rgba(0, 0, 0, 0.1) !important;':''"
                  large
                >
                  mdi-history
                </v-icon>
              </v-btn>
            </template>
            <span>Отменить изменения</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                text
                color="red accent-2"
                v-on="on"
                :dark="tableChange"
                :disabled="!tableChange"
                @click="saveStyleChange"
              >
                <v-icon
                  large
                >
                  <!--              :style="!tableChange?'color: rgba(0, 0, 0, 0.1) !important;':''"-->
                  mdi-content-save
                </v-icon>
              </v-btn>
            </template>
            <span>Сохранить изменения</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th
            v-for="(item, index) in header"
            :key="'styleHeader' + index"
          >
            {{ item.substring(0, 6) }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(itemTr, indexBody) in bodyCopy"
          :key="'style' + indexBody"
          :class="{ 'hover-background': hoverRow === indexBody || choiceRowActive === itemTr.id }"
          @click="choiceRow(itemTr)"
          @mouseenter="hoverRow = indexBody"
          @mouseleave="hoverRow = null"
        >
          <td
            v-for="(itemTh, index) in header"
            :class="{ 'disabled': disabledMarksId && (itemTh === 'plrowid' || itemTh === 'plcolid') }"
            :key="'style' + index"
            @click="choiceCell(itemTh, indexBody)"
          >
            <span v-if="itemTh === 'stylesid'">
              <v-tooltip
                bottom
                max-width="500"
              >
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    style="cursor: pointer"
                    v-on="on"
                  >
                    <span v-if="itemTr[itemTh] || itemTr[itemTh] === 0">({{ itemTr[itemTh] }})</span> {{ itemTr.description?.substring(0, 6) }}
                  </span>
                </template>
                <div>
                  {{ itemTr.description }}
                </div>
              </v-tooltip>
              <v-btn
                x-small
                dark
                icon
                style="position: absolute; top: 50%; transform: translate(0, -50%); right: 0;"
                :color="'blue-grey lighten-3'"
                @click="modalSelect(itemTr, itemTh)"
              >
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </span>
            <span v-else-if="itemTh === 'title'">
              <v-tooltip
                bottom
                max-width="500"
              >
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    style="cursor: pointer"
                    v-on="on"
                  >
                    <span v-if="itemTr[itemTh] || itemTr[itemTh] === 0">({{ itemTr.categoryid }})</span> {{ itemTr.title?.substring(0, 6) }}
                  </span>
                </template>
                <div>
                  {{ itemTr.title }}
                </div>
              </v-tooltip>
              <v-btn
                x-small
                dark
                icon
                style="position: absolute; top: 50%; transform: translate(0, -50%); right: 0;"
                :color="'blue-grey lighten-3'"
                @click="modalSelect(itemTr, itemTh)"
              >
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </span>
            <span v-else-if="itemTh === choiceItem && indexBody === indexBody2 && !(itemTh === 'plrowid' || itemTh === 'plcolid')">
              <v-text-field
                v-model="itemTr[itemTh]"
                class="input-table"
                @keydown.enter.native.prevent
              />
            </span>
            <span v-else>
              {{ itemTr[itemTh] }}
              <v-btn
                v-if="itemTh === 'plrowid' || itemTh === 'plcolid'"
                x-small
                dark
                icon
                style="position: absolute; top: 50%; transform: translate(0, -50%); right: 0;"
                :color="'blue-grey lighten-3'"
                @click="modalSelect(itemTr, itemTh)"
              >
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </span>
          </td>
          <th
            class="settings"
          >
            <v-btn
              icon
              tile
              text
              small
              class="pl-0"
              @click.stop="delElTable(itemTr.id)"
            >
              <v-icon>
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </th>
        </tr>
      </tbody>
    </table>
    <v-dialog
      v-if="objectsDialog"
      v-model="objectsDialog"
      width="960"
    >
      <v-card>
        <v-card-text class="d-flex">
          <v-autocomplete
            v-model="curElemObjects"
            item-text="title"
            item-value="id"
            :items="objectsList"
            dense
            flat
            hide-no-data
            hide-details
            full-width
            clearable
            autofocus
            class="withoutbg mt-0 pl-2"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="updateListObject(curElemObjects)"
          >
            ok
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <mdialog ref="dlgDel" />
  </div>
</template>

<script>
  import mdialog from '../../../../components/mdialog.vue'
  export default {
    name: 'builderTemplateSettingsTableInTable',
    components: {
      mdialog,
    },
    props: {
      header: {
        type: Array,
        default: () => [],
      },
      body: {
        type: Array,
        default: () => [],
      },
      tableDate: {
        type: Object,
        default: () => {},
      },
      type: {
        type: String,
        default: 'style',
      },
      selectedTableElements: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        showFilter: false,
        searchText: null,
        filterChoice: [],
        searchFilterActive: false,
        hoverRow: null,
        bodyCopy: null,
        choiceRowActive: null,
        allCols: [],
        objectsDialog: false,
        objectsList: [],
        curElemObjects: null,
        curChangedObject: {
          changedItem: null,
          typeItem: null,
        },
        choiceItem: null,
        indexBody2: null,
        changedElem: [],
        differentRows: [],
        newRows: [],
        allTableStyles: null,
        allTableMarks: null,
        hasActiveStartTemplate: false,
        stylePresentationMode: false,
      }
    },
    computed: {
      tableChange () {
        const change = JSON.stringify(this.body) !== JSON.stringify(this.bodyCopy)
        return change
      },
      likesAllCols () {
        if (this.filterChoice) {
          return this.filterChoice.length === this.allCols.length
        } else {
          return false
        }
      },
      likesSomeCols () {
        if (this.filterChoice) {
          return this.filterChoice.length > 0 && !this.likesAllCols
        } else {
          return false
        }
      },
      iconCols () {
        if (this.likesAllCols) return 'mdi-close-box'
        if (this.likesSomeCols) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      disabledMarksId () {
        return this.selectedTableElements?.colId || this.selectedTableElements?.rowId || this.selectedTableElements?.cell
      },
    },
    watch: {
      body: {
        handler () {
          this.$emit('changeFilterCheckbox')
          this.bodyCopy = JSON.parse(JSON.stringify(this.body))
          this.allCols = [...new Set(this.bodyCopy?.map(obj => obj?.title))]
        },
        immediate: true,
      },
      selectedTableElements: {
        handler () {
          if (this.selectedTableElements?.cell) {
            this.bodyCopy = this.body.filter(item => item.plrowid === this.selectedTableElements.cell.rowId && item.plcolid === this.selectedTableElements.cell.colId)
          }
        },
        immediate: true,
      },
      searchText () {
        this.searchText ? this.searchFilterActive = true : this.searchFilterActive = false
      },
      filterChoice () {
        if (this.filterChoice?.length) {
          this.bodyCopy = this.body.filter(obj => this.filterChoice.includes(obj.title))
        } else this.bodyCopy = JSON.parse(JSON.stringify(this.body))
      },
    },
    async mounted () {
      await this.$store.dispatch('getTableStylesAll').then((response) => {
        this.allTableStyles = response
      }).catch((e) => {
        console.error('Error: ', e)
      })
      await this.$store.dispatch('getTableMarksAll').then((response) => {
        this.allTableMarks = response
      }).catch((e) => {
        console.error('Error: ', e)
      })
    },
    methods: {
      addNewRow () {
        const newElem = {}
        if (this.type === 'style') {
          newElem.plcolid = null
          newElem.plrowid = null
          newElem.pltableid = this.tableDate.info.id
          newElem.stylesid = null
          newElem.priority = null
          newElem.can_edit = null
          newElem.flgheader = 0
          newElem.flg_presentation = null
        }
        if (this.type === 'mark') {
          newElem.pltableid = this.tableDate.info.id
          newElem.value_int = null
          newElem.value_str = null
          newElem.categoryid = null
          if (this.selectedTableElements?.cell) {
            newElem.plrowid = this.selectedTableElements.cell.rowId
            newElem.plcolid = this.selectedTableElements.cell.colId
          } else if (this.selectedTableElements?.rowId) {
            newElem.plrowid = this.selectedTableElements.rowId
            newElem.plcolid = null
          } else if (this.selectedTableElements?.colId) {
            newElem.plrowid = null
            newElem.plcolid = this.selectedTableElements.colId
          } else {
            newElem.plrowid = null
            newElem.plcolid = null
          }
        }
        this.bodyCopy.push(newElem)
      },
      delElTable (id) {
        this.$refs.dlgDel.open({
          title: 'Удалить объект?',
          acceptText: 'Продолжить',
          cancelText: 'Отмена',
          dialogMaxWidth: 440,
        }).then(() => {
          if (this.type === 'style') {
            const payload = {
              id: id,
            }
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('delTableStyle', payload).then(() => {
              this.$emit('updateSettingsTableInTable')
            }).catch((e) => {
              console.error('Error: ', e)
              this.$store.dispatch('setDataLoading', false)
            })
          }
          if (this.type === 'mark') {
            const payload = {
              id: id,
            }
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('delMarkTable', payload).then(() => {
              this.$emit('updateSettingsTableInTable')
            }).catch((e) => {
              console.error('Error: ', e)
              this.$store.dispatch('setDataLoading', false)
            })
          }
        })
      },
      toggleCols () {
        if (!this.searchFilterActive) {
          this.$nextTick(() => {
            if (this.likesAllCols) {
              this.filterChoice = null
            } else {
              this.filterChoice = this.allCols.map(v => v)
            }
          })
        } else {
          const filteredItems = this.allCols.filter(item =>
            item?.toLowerCase().includes(this.searchText?.toLowerCase())
          )
          if (this.filterChoice?.length === filteredItems.length) {
            this.filterChoice = null
          } else {
            this.filterChoice = filteredItems.map(v => v)
          }
        }
      },
      choiceRow (style) {
        this.choiceId = style.id
        this.$emit('choiceRow', style)
        this.choiceRowActive = style.id
      },
      choiceCell (item, indexBody) {
        this.choiceItem = item
        this.indexBody2 = indexBody
      },
      async modalSelect (el, type) {
        this.curChangedObject.changedItem = el
        this.curChangedObject.typeItem = type
        this.objectsDialog = true
        if (type === 'plcolid') {
          this.objectsList = this.tableDate.col.reduce((result, obj) => {
            if (!result.find(item => item.plcolid === obj.id)) {
              result.push({
                id: obj.id,
                title: `${obj.title || obj.description} (${obj.id})`,
              })
              if (obj.id === el[type]) {
                this.curElemObjects = {
                  id: el[type],
                  title: obj.title,
                }
              }
            }
            return result
          }, [])
        }
        if (type === 'plrowid') {
          this.objectsList = this.tableDate.row.reduce((result, obj) => {
            if (!result.find(item => item.plrowid === obj.id)) {
              result.push({
                id: obj.id,
                title: `${obj.title} (${obj.id})`,
              })
              if (obj.id === el[type]) {
                this.curElemObjects = {
                  id: el[type],
                  title: obj.title,
                }
              }
            }
            return result
          }, [])
        }
        if (type === 'stylesid') {
          this.objectsList = []
          this.allTableStyles.forEach((item) => {
            this.objectsList.push({
              id: item.id,
              title: `${item.style_value} (${item.id})`,
            })
          })
          this.curElemObjects = {
            id: el[type],
            title: el.description,
          }
        }
        if (type === 'title') {
          this.objectsList = []
          this.allTableMarks.forEach((item) => {
            this.objectsList.push({
              id: item.id,
              title: `${item.title} (${item.id})`,
            })
          })
          this.curElemObjects = {
            id: el.categoryid,
            title: el.title,
          }
        }
      },
      updateListObject (curElemObjects) {
        this.curChangedObject.changedItem[this.curChangedObject.typeItem] = curElemObjects
        if (this.curChangedObject.typeItem === 'stylesid') { // подтягиваем description по выбранному styles id
          const styleById = this.allTableStyles.find(obj => obj.id === this.curChangedObject.changedItem.stylesid)
          this.curChangedObject.changedItem.description = styleById.style_value
        }
        if (this.curChangedObject.typeItem === 'title') { // подтягиваем title по выбранному styles id
          const markById = this.allTableMarks.find(obj => obj.id === this.curChangedObject.changedItem.title)
          this.curChangedObject.changedItem.categoryid = markById.id
          this.curChangedObject.changedItem.title = markById.title
        }
        this.objectsDialog = false
      },
      reverseChanges () {
        this.bodyCopy = JSON.parse(JSON.stringify(this.body))
      },
      saveStyleChange () {
        // Находим и изменяем только те элементы которые изменились
        this.differentRows = []
        this.newRows = []
        for (let i = 0; i < this.bodyCopy.length; i++) {
          const obj1 = this.bodyCopy[i]
          let isEqual = false
          for (let j = 0; j < this.body.length; j++) {
            const obj2 = this.body[j]
            if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
              isEqual = true
              break
            }
          }
          if (i >= this.body.length) {
            this.newRows.push(obj1)
            break
          }
          if (!isEqual) {
            this.differentRows.push(obj1)
          }
        }
        this.newRows = this.bodyCopy.slice(this.body.length)
        if (this.type === 'style') {
          if (this.differentRows.length) {
            this.updateStyles()
          }
          if (this.newRows.length) {
            this.newRows.forEach((item) => {
              this.addStyles(item)
            })
          }
        }
        if (this.type === 'mark') {
          if (this.differentRows.length) {
            this.updateMark()
          }
          if (this.newRows.length) {
            this.newRows.forEach((item) => {
              this.addMarks(item)
            })
          }
        }
      },
      updateStyles () {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('updateStylesTableInfo', this.differentRows).then(() => {
          this.$emit('updateSettingsTableInTable')
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      addStyles (row) {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('addTableStyles', row).then(() => {
          this.$emit('updateSettingsTableInTable')
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      addMarks (row) {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('addMarksTable', row).then(() => {
          this.$emit('updateSettingsTableInTable')
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      updateMark () {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('updateMarkTable', this.differentRows).then(() => {
          this.$emit('updateSettingsTableInTable')
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      changeStylePresentationMode () {
        this.$emit('changeStylePresentationMode', this.stylePresentationMode)
      },
    },
  }
</script>

<style scoped lang="scss">
.table-header {
  width: 100%;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead {
    th {
      width: 20px;
    }
  }
  th, td {
    padding: 2px 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
  td {
    position: relative;
    width: 20px;
    .header-settings-elem {
      position: absolute;
      top: 50%;
      right: 7px;
      transform: translate(0, -50%);
      cursor: pointer;
    }
    span {
      display: block;
      line-height: 20px;
      width: 42px;
    }
  }
  .hover-background {
    background-color: #f5f5f5 !important;
    .v-input {
      margin: 0;
      padding: 0;
      .v-text-field__details {
        display: none !important;
      }
    }
  }
  .settings {
    cursor: pointer;
  }
  .inform-tooltip {
    position: absolute;
    top: 3px;
    right: 12px;
    width: 20px;
    height: 20px;
  }
  .input-table {
    input {
      font-size: 8px !important;
    }
  }
}
.disabled {
  pointer-events: none;
  opacity: .5;
}
</style>
