<template>
  <v-row class="row-wrapper">
    <v-col
      v-for="item in selectedFieldsOnList"
      :key="item.field"
      class="col-item"
      sm="12"
    >
      <v-text-field
        v-if="!item.checkbox && !item.select"
        v-model="chosenElementCopy[item.field]"
        :disabled="item.disabled"
        :label="item.label"
        required
        clearable
        inputmode="numeric"
      />
      <v-checkbox
        v-else-if="!item.select"
        v-model="chosenElementCopy[item.field]"
        :label="item.label"
      />
      <v-select
        v-else
        v-model="chosenElementCopy[item.field]"
        :items="typesSelect[item.selectType]"
        item-value="value"
        item-text="label"
        :label="item.label"
      />
      <v-tooltip
        v-if="item.tooltip"
        bottom
        class="data-mask__tooltip"
        max-width="500"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="data-mask__tooltip"
            v-bind="attrs"
            dark
            icon
            :color="'blue-grey lighten-3'"
            v-on="on"
          >
            <v-icon>
              mdi-information
            </v-icon>
          </v-btn>
        </template>
        <div v-html="item.tooltipText" />
      </v-tooltip>
    </v-col>
    <v-btn
      v-if="addButton"
      color="success"
      class="mx-auto"
      @click="$emit('addElement', chosenElementCopy)"
    >
      Добавить
    </v-btn>
    <v-btn
      v-if="editButton"
      color="amber"
      class="mx-auto"
      @click="$emit('editElement', chosenElementCopy)"
    >
      Редактировать
    </v-btn>
    <v-btn
      v-if="delButton"
      color="red lighten-1"
      class="mx-auto"
      @click="$emit('delElement', chosenElementCopy.id, type)"
    >
      Удалить
    </v-btn>
  </v-row>
</template>

<script>
  export default {
    name: 'builderTemplateFormBody',
    props: {
      selectedFieldsOnList: {
        type: Array,
        default: () => [],
        require: true,
      },
      chosenElementInList: {
        type: Object,
        default: () => {},
        require: true,
      },
      addButton: {
        type: Boolean,
        default: false,
      },
      editButton: {
        type: Boolean,
        default: false,
      },
      delButton: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        chosenElementCopy: {},
        typesSelect: {
          forPeriod: [
            {
              value: 1,
              label: '1 - год',
            },
            {
              value: 2,
              label: '2 - месяц+год',
            },
            {
              value: 3,
              label: '3 - неделя+год',
            },
            {
              value: 4,
              label: '4 - день',
            },
          ],
          dataType: [
            {
              value: null,
              label: '0 - Не выбрано',
            },
            {
              value: 1,
              label: '1 - value_int',
            },
            {
              value: 2,
              label: '2 - value_dbl',
            },
            {
              value: 3,
              label: '3 - value_str',
            },
          ],
          colType: [
            {
              value: -2,
              label: '-2 - основной столбец',
            },
            {
              value: -1,
              label: '-1 - столбец "№ п/п"',
            },
            {
              value: 0,
              label: '0 - обычный столбец (статический',
            },
            {
              value: 1,
              label: '1 - периодический столбец: годы',
            },
            {
              value: 2,
              label: '2 - периодический столбец: месяцы',
            },
            {
              value: 3,
              label: '3 - периодический столбец: недели',
            },
            {
              value: 4,
              label: '4 - периодический столбец: дни',
            },
          ],
        },
      }
    },
    mounted () {
      this.chosenElementCopy = JSON.parse(JSON.stringify(this.chosenElementInList))
    },
    watch: {
      chosenElementInList: {
        handler () {
          this.chosenElementCopy = JSON.parse(JSON.stringify(this.chosenElementInList))
        },
        deep: true,
      },
    },
  }
</script>

<style scoped lang="scss">
.col-item {
  position: relative;
  .data-mask__tooltip {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
